const CheckboxIcon = ({ checked, onChange }) => {
  return (
    <svg
      className={`checkbox-icon ${checked === true ? 'checked' : ''}`}
      onClick={onChange}
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
    >
      <path
        d='M0.5 2C0.5 1.17157 1.17157 0.5 2 0.5H18C18.8284 0.5 19.5 1.17157 19.5 2V18C19.5 18.8284 18.8284 19.5 18 19.5H2C1.17157 19.5 0.5 18.8284 0.5 18V2Z'
        stroke='#1D9E48'
        className={`checkbox-background ${checked === true ? 'checked' : ''}`}
      />
      <path
        d='M5 9L9 13L15 7'
        stroke='#FAFAFA'
        strokeWidth='1.5'
        strokeLinecap='round'
        className='checkbox-tick'
      />
    </svg>
  )
}

export default CheckboxIcon
