/* ---------------------------------
Button
--------------------------------- */

import PropTypes from 'prop-types'
import classNames from 'classnames'
import { extractStyleProps } from 'utils/styleProps'

export default function Button({
  variant,
  onClick,
  className,
  children,
  ...styleProps
}) {
  return (
    <button
      onClick={onClick}
      className={classNames(
        'Button',
        className,

        // Variants
        variant === 'cta' && 'Button--cta',
        variant === 'login' && 'Button--login',

        // Style props
        extractStyleProps(styleProps)
      )}
    >
      {children}
    </button>
  )
}

Button.propTypes = {
  variant: PropTypes.oneOf(['cta', 'login']),
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  styleProps: PropTypes.object,
}
