'use client'
import { useRef, useState } from 'react'

const Iframe = ({
  iFrameUrl,
  title,
  subTitle,
  className = '',
  style = {},
  onError = () => {},
  onLoaded = () => {},
}) => {
  const iframeRef = useRef(null)
  const [isLoading, setIsLoading] = useState(true)

  const handleLoad = () => {
    setIsLoading(false)
    onLoaded()
  }

  const handleError = (error) => {
    setIsLoading(false)
    onError(error)
  }

  return (
    <div className={`iframe ${className}`}>
      {title && <h1 className='iframe__title'>{title}</h1>}
      {subTitle && <p className='iframe__sub-title'>{subTitle}</p>}
      {isLoading && <div className='iframe__spinner'>Loading...</div>}
      <iframe
        ref={iframeRef}
        src={iFrameUrl}
        className={`iframe__content ${isLoading ? 'iframe__content--loading' : ''}`}
        style={{
          visibility: isLoading ? 'hidden' : 'visible',
          ...style,
        }}
        onLoad={handleLoad}
        onError={handleError}
      />
    </div>
  )
}

export default Iframe
