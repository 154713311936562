/* ---------------------------------
DonorStoriesSection
--------------------------------- */

import {
  Button,
  OpeningTitle,
  Paragraph,
  Section,
  Title,
} from 'components/atoms'
import { Slider, TestimonialCard, VideoCard } from 'components/molecules'
import { useImageSize } from '@/hooks/useImageSize'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useRouter } from 'next/navigation'

export default function DonorStoriesSection({ data }) {
  const { attributes } = data ?? {}
  const {
    // TODO data in mocks
    openingTitle,
    title,
    paragraph,
    slides,
    ctaButton,
    testimonialCard,
    testimonialCardPosition,
    layout,
    bg: sectionBg,
    textAlign,
  } = attributes ?? {}

  const router = useRouter()

  const { image: imageSize, queries } = useImageSize()
  const { isLG, isXL } = queries

  return (
    <Section
      bg={sectionBg}
      className={classNames(
        'DonorStoriesSection',
        layout === 'invert_cols' && 'DonorStoriesSection--layout_invert_cols'
      )}
      pyXXL={isXL}
    >
      <div className='DonorStoriesSection__grid'>
        <div className='DonorStoriesSection__grid__col-1'>
          {openingTitle ? (
            <OpeningTitle
              mbXS
              taLeft={isLG || textAlign === 'left'}
            >
              {openingTitle}
            </OpeningTitle>
          ) : null}

          {title ? (
            <Title
              mt0
              mbXS
              level={2}
              taLeft={isLG || textAlign === 'left'}
            >
              {/* TODO sanitize */}
              <span dangerouslySetInnerHTML={{ __html: title }} />
            </Title>
          ) : null}

          {paragraph ? (
            <Paragraph
              mbM={!isLG || (isLG && !!ctaButton)}
              taLeft={isLG || textAlign === 'left'}
            >
              {paragraph}
            </Paragraph>
          ) : null}

          {testimonialCard && isLG && testimonialCardPosition === 'left_col' ? (
            <div style={{ marginBottom: 'var(--Spacing-M)', maxWidth: 500 }}>
              <TestimonialCard
                title={testimonialCard.data?.attributes?.title}
                subtitle={testimonialCard.data?.attributes?.subtitle}
                image={testimonialCard.data?.attributes?.image?.url}
              >
                {testimonialCard.data?.attributes?.text}
              </TestimonialCard>
            </div>
          ) : null}

          {ctaButton ? (
            <Button
              className={'EditorialSection__cta-button'}
              variant={'cta'}
              // TODO what if it's a fully qualified URL
              onClick={() => router.push(ctaButton?.url)}
            >
              {ctaButton?.label}
            </Button>
          ) : null}
        </div>

        <div className='DonorStoriesSection__grid__col-2'>
          {slides?.length ? (
            slides.length === 1 ? (
              // TODO once mocks are removed, each slide
              // would construct an ImageCard or VideoCard
              // depending on the type.
              <VideoCard
                mbM
                // TODO
                video={{
                  src: '',
                  poster: isXL
                    ? `https://picsum.photos/${Object.values(imageSize)
                        .join()
                        .replace(',', '/')}`
                    : 'https://picsum.photos/380/580',
                }}
              />
            ) : (
              <Slider
                slides={slides.fill(
                  <VideoCard
                    mbM
                    // TODO
                    video={{
                      src: '',
                      poster: isXL
                        ? `https://picsum.photos/${Object.values(imageSize)
                            .join()
                            .replace(',', '/')}`
                        : 'https://picsum.photos/380/580',
                    }}
                  />
                )}
              >
                {/* TODO this probably has to be part of the slide together with the video */}
                {testimonialCard &&
                (!isLG || testimonialCardPosition === 'right_col') ? (
                  <TestimonialCard
                    title={testimonialCard.data?.attributes?.title}
                    subtitle={testimonialCard.data?.attributes?.subtitle}
                    image={testimonialCard.data?.attributes?.image?.url}
                  >
                    {testimonialCard.data?.attributes?.text}
                  </TestimonialCard>
                ) : null}
              </Slider>
            )
          ) : null}
        </div>
      </div>
    </Section>
  )
}

DonorStoriesSection.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    attributes: PropTypes.shape({
      // this would represent a CMS enumeration
      layout: PropTypes.oneOf(['invert_cols', 'single_col']),
      bg: PropTypes.oneOf([
        'dark',
        'black',
        'blue',
        'dark-blue',
        'dark-green',
        'green',
        'grey-050',
        'grey-100',
        'grey-200',
        'grey-300',
        'grey-500',
        'grey-700',
        'grey-900',
        'rich-blue',
        'white',
      ]),
      textAlign: PropTypes.oneOf(['left', 'center']),
      testimonialCardPosition: PropTypes.oneOf(['left_col', 'right_col']),
      openingTitle: PropTypes.string,
      title: PropTypes.string,
      paragraph: PropTypes.string,
      ctaButton: PropTypes.shape({
        label: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
      testimonialCard: PropTypes.shape({
        data: PropTypes.shape({
          id: PropTypes.number,
          attributes: PropTypes.shape({
            title: PropTypes.string,
            subtitle: PropTypes.string,
            image: PropTypes.shape({
              url: PropTypes.string,
            }),
            text: PropTypes.string,
            createdAt: PropTypes.string,
            updatedAt: PropTypes.string,
            publishedAt: PropTypes.string,
          }),
        }),
      }),
      slides: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.oneOf(['video', 'image']),
          poster: PropTypes.string,
          url: PropTypes.string.isRequired,
          alt: PropTypes.string,
          width: PropTypes.number.isRequired,
          height: PropTypes.number.isRequired,
        })
      ),
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      publishedAt: PropTypes.string,
    }),
  }).isRequired,
}
