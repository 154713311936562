'use client'

import { Fragment, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

const RadioGroup = ({
  label = 'Ricorrenza della donazione',
  classNames = {
    radioGroup: 'radio-group',
    radioOption: 'radio-option',
    radioOptionLabel: 'radio-option_label',
    radioOptionSubLabel: 'radio-option_sublabel',
  },
  name = 'donation-period',
  defaultValue = '',
  radioOptions = [
    {
      value: 'mensile',
      label: 'Mensile',
      subLabel: '',
      name: 'donation-period',
    },
    {
      value: 'semestrale',
      label: 'Semestrale',
      subLabel: '',
      name: 'donation-period',
    },
    {
      value: 'custom',
      label: 'Annuale',
      subLabel: '',
      name: 'donation-period',
    },
  ],
  isHidden = false,
  disableQueryState = false,
  value = '',
  useFormContextIfAvailable = false,
  config,
}) => {
  const {
    register,
    watch,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState,
  } = useFormContext()
  const currentFormSelectValue = getValues(name) || value || defaultValue
  const [chosenOptionQuery, setChosenOptionQuery] = useState(
    useFormContextIfAvailable ? currentFormSelectValue : value || defaultValue
  )
  const [chosenRadioInputValue, setChosenRadioInputValue] = useState(
    chosenOptionQuery.startsWith('custom-number-')
      ? chosenOptionQuery.split('-')[2]
      : 1
  )

  const hasCustomOptionSelected =
    chosenOptionQuery.startsWith('custom') && chosenRadioInputValue
  const customOptionType = 'number'

  const chosenOptionFormState = watch(name) ?? chosenOptionQuery
  const { onChange, ref } = register(name)

  const isRadioButtonChecked = (value) => {
    return (
      chosenOptionQuery.startsWith(value) ||
      chosenOptionFormState.startsWith(value)
    )
  }

  const handleChange = (e) => {
    const value = e.target.value
    onChange(e)
    setChosenOptionQuery(value)
    if (hasCustomOptionSelected) {
      setChosenRadioInputValue(null)
    }
  }
  const addCustomValue = (value) => {
    if (value.startsWith('custom-number')) {
      if (chosenOptionQuery.startsWith('custom-number-')) {
        return chosenOptionQuery
      }
      if (value === 'custom-number') {
        return `${value}-${chosenRadioInputValue}`
      }
    }
    return value
  }

  useEffect(() => {
    const configChecks = Object.keys(config)
    if (configChecks.includes('min')) {
      const _currentValue = chosenOptionQuery.startsWith('custom-number-')
        ? Number(chosenOptionQuery.split('-')[2])
        : Number(chosenOptionQuery)
      if (_currentValue < config.min.value) {
        setError(name, {
          type: 'custom',
          message: `Il valore minimo è ${config.min.value} €`,
        })
      } else {
        clearErrors(name)
      }
    }
  }, [
    chosenOptionQuery,
    chosenRadioInputValue,
    clearErrors,
    config,
    name,
    setError,
  ])

  if (radioOptions.length === 0) {
    return null
  }

  return (
    <div
      id={name}
      className={classNames.radioGroup}
      data-options={radioOptions.length}
      data-variant={name}
      data-current-option={chosenOptionQuery.split('-')[0]}
    >
      {radioOptions.map(({ value, label, subLabel }, index) => (
        <Fragment key={value}>
          <label
            key={index}
            className={`${classNames.radioOption} ${
              chosenOptionQuery.startsWith('custom') &&
              value.startsWith('custom')
                ? 'custom'
                : ''
            }`}
          >
            <input
              {...register(name)}
              type={isHidden ? 'hidden' : 'radio'}
              // type={'radio'}
              name={name}
              value={String(addCustomValue(value))}
              checked={isRadioButtonChecked(value)}
              onChange={handleChange}
            />
            <span className={classNames.radioOptionLabel}>{label}</span>
            {subLabel && (
              <span className={classNames.radioOptionSubLabel}>{subLabel}</span>
            )}
          </label>
          {isRadioButtonChecked(value) && value.includes('custom-number') && (
            <div className='radio-option_custom-wrapper'>
              <input
                className={`radio-option_custom-radio ${
                  chosenRadioInputValue ||
                  chosenOptionQuery.startsWith('custom')
                    ? 'active'
                    : ''
                }`}
                autoFocus
                type={isHidden ? 'hidden' : customOptionType}
                // type={customOptionType}
                pattern='[0-9]*'
                min={1}
                step={0.5}
                value={chosenRadioInputValue}
                onChange={(e) => {
                  const isValid =
                    !isNaN(e.target.value) || e.target.value === ''
                  if (!isValid) {
                    return
                  }
                  const value = Number(e.target.value)
                  setValue(name, `custom-number-${value}`)
                  setChosenOptionQuery(`custom-number-${value}`)
                  setChosenRadioInputValue(value)
                }}
              />
              <span
                className={`radio-option_euro ${
                  chosenRadioInputValue ||
                  chosenOptionQuery.startsWith('custom')
                    ? 'active'
                    : ''
                }`}
              >
                €
              </span>
            </div>
          )}
        </Fragment>
      ))}
    </div>
  )
}

export default RadioGroup
