/* ---------------------------------
StoriesSection
--------------------------------- */

import {
  Anchor,
  OpeningTitle,
  Paragraph,
  Section,
  Title,
} from 'components/atoms'
import { ImageCard, Slider } from 'components/molecules'
import { useImageSize } from '@/hooks/useImageSize'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export default function StoriesSection({ data }) {
  const { attributes } = data ?? {}
  const {
    openingTitle,
    title,
    paragraph,
    slides,
    bg: sectionBg,
    textAlign,
    paragraphPosition,
  } = attributes ?? {}

  const { image: imageSize, queries } = useImageSize()
  const { isXL, isLG } = queries

  return (
    <Section
      bg={sectionBg}
      className={classNames(
        'StoriesSection',
        paragraphPosition === 'bottom' && 'StoriesSection--layout_parag_bottom'
      )}
      pyXXL={isXL}
    >
      <div className='StoriesSection__text-content'>
        <div className='StoriesSection__text-content__titles'>
          {openingTitle ? (
            <OpeningTitle
              mbXS
              taLeft={isLG || textAlign === 'left'}
            >
              {openingTitle}
            </OpeningTitle>
          ) : null}

          {title ? (
            <Title
              mt0
              mbM
              level={2}
              taLeft={isLG || textAlign === 'left'}
            >
              {/* TODO sanitize */}
              <span dangerouslySetInnerHTML={{ __html: title }} />
            </Title>
          ) : null}
        </div>

        {paragraph ? (
          <Paragraph
            className={'StoriesSection__text-content__body'}
            taLeft={isLG || textAlign === 'left'}
          >
            {paragraph}
          </Paragraph>
        ) : null}
      </div>

      {slides?.length ? (
        slides.length === 1 ? (
          // TODO once mocks are removed, each slide
          // would construct an ImageCard or VideoCard
          // depending on the type.
          <ImageCard
            className={'StoriesSection__image-card'}
            mbM
            title={'Nome, NN anni'}
            body={'AIRC mi ha donato nuova luce quando ne ho avuto più bisogno'}
            cta={<Anchor href={'/'}>Call to action</Anchor>}
            image={
              isXL
                ? `https://picsum.photos/${Object.values(imageSize)
                    .join()
                    .replace(',', '/')}`
                : 'https://picsum.photos/380/580'
            }
          />
        ) : (
          <Slider
            slides={slides.fill(
              <ImageCard
                className={'StoriesSection__image-card'}
                mbM
                title={'Nome, NN anni'}
                body={
                  'AIRC mi ha donato nuova luce quando ne ho avuto più bisogno'
                }
                cta={<Anchor href={'/'}>Call to action</Anchor>}
                image={
                  isXL
                    ? `https://picsum.photos/${Object.values(imageSize)
                        .join()
                        .replace(',', '/')}`
                    : 'https://picsum.photos/380/580'
                }
              />
            )}
          />
        )
      ) : null}
    </Section>
  )
}

StoriesSection.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    attributes: PropTypes.shape({
      // this would represent a CMS enumeration
      layout: PropTypes.oneOf(['invert_cols', 'single_col']),
      bg: PropTypes.oneOf([
        'dark',
        'black',
        'blue',
        'dark-blue',
        'dark-green',
        'green',
        'grey-050',
        'grey-100',
        'grey-200',
        'grey-300',
        'grey-500',
        'grey-700',
        'grey-900',
        'rich-blue',
        'white',
      ]),
      textAlign: PropTypes.oneOf(['left', 'center']),
      paragraphPosition: PropTypes.oneOf(['right', 'bottom']),
      openingTitle: PropTypes.string,
      title: PropTypes.string,
      paragraph: PropTypes.string,
      slides: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.oneOf(['video', 'image']),
          poster: PropTypes.string,
          url: PropTypes.string.isRequired,
          alt: PropTypes.string,
          width: PropTypes.number.isRequired,
          height: PropTypes.number.isRequired,
        })
      ),
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      publishedAt: PropTypes.string,
    }),
  }).isRequired,
}
