const SERVER_PAYMENTS_METHODS = {
  DEPRECATED_PAYPAL: 'paypal',
  DEPRECATED_CREDITCARD: 'creditcard',
  DEPRECATED_MYBANK: 'mybank',
  DEPRECATED_POSTEPAY: 'bancoposta-postepay',
  PAYPAL: 'paypal-2022',
  CREDITCARD: 'creditcard-2022',
  MYBANK: 'paypal-mybank-2022',
  POSTEPAY: 'bancoposta-postepay',
  TRANSFER: 'bonifico-bancario',
  ACCOUNT: 'conto-corrente-postale',
  SATISPAY: 'satispay',
}

export { SERVER_PAYMENTS_METHODS }
