'use client'

import { CheckboxIcon } from '@/svg'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'

const componentClassName = 'checkbox'

const valuePicker = (value, defaultValue, formValue, useForm) => {
  const isValidValue = (value) => {
    return (
      value !== undefined &&
      value !== null &&
      value !== '' &&
      value !== 'undefined'
    )
  }
  if (isValidValue(value)) {
    return value
  }

  if (useForm && isValidValue(formValue)) {
    return formValue
  }

  return defaultValue
}

const Checkbox = ({
  name,
  content,
  onChange = () => {},
  defaultValue = false,
  useFormContextIfAvailable = false,
  config = {},
}) => {
  const formContext = useFormContext()
  const checkboxFromValue = formContext.getValues(name) || defaultValue || false
  const [isChecked, setIsChecked] = useState(
    valuePicker(
      null,
      defaultValue,
      checkboxFromValue,
      useFormContextIfAvailable
    )
  )

  const isPrivacyPolicy = name === 'privacy'

  const handleChange = (e) => {
    const _isChecked = Boolean(!isChecked)
    setIsChecked(_isChecked)
    onChange(e)
    if (useFormContextIfAvailable && _isChecked) {
      formContext.setValue(name, _isChecked)
    }
  }

  const renderPrivacyContent = () => {
    return (
      <span>
        Per completare la donazione, è necessario leggere e comprendere la{' '}
        <a
          href='https://donazione.airc.it/privacy'
          target='_blank'
        >
          privacy policy
        </a>
        {/* e i{' '}
        <a
          href='https://donazione.airc.it/privacy'
          target='_blank'
        >
          termini e condizioni.
        </a> */}
      </span>
    )
  }

  const checkBoxProps = useFormContextIfAvailable
    ? {
        ...formContext.register(name, config),
        onChange: handleChange,
        checked: isChecked,
      }
    : {
        name,
        checked: isChecked,
        onChange: handleChange,
      }

  return (
    <div className={`${componentClassName}_wrapper`}>
      <CheckboxIcon
        checked={isChecked}
        onChange={handleChange}
      />
      <label className={`${componentClassName}_checkbox `}>
        <input
          className={`${componentClassName}_input `}
          type='checkbox'
          name={name}
          checked={isChecked}
          {...checkBoxProps}
        />
        <span
          htmlFor={name}
          className={`${componentClassName}_label `}
        >
          {isPrivacyPolicy ? renderPrivacyContent() : content}
        </span>
      </label>
    </div>
  )
}

export default Checkbox
