import axios from 'axios'

if (!process.env.NEXT_PUBLIC_API_BASE_URL) {
  throw new Error(
    "[❌ Error : NEXT_PUBLIC_API_BASE_URL non è definito nelle variabili d'ambiente ]"
  )
} else {
  console.info(
    '[ℹ️ NEXT_PUBLIC_API_BASE_URL:',
    process.env.NEXT_PUBLIC_API_BASE_URL
  )
}

const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PULIC_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

axiosInstance.interceptors.request.use(
  (response) => {
    // set the base url
    response.baseURL = process.env.NEXT_PUBLIC_API_BASE_URL
    // Do something before request is sent
    console.info(
      '[➡️ Request Sent - ',
      `${response.method.toUpperCase()} -`,
      response.baseURL + response.url + ' ]'
    )
    return response
  },
  (error) => {
    // Do something with request error
    console.error('[❌ Request Error:', error)
    Promise.reject(error)
  }
)

export default axiosInstance
