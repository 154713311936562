/* ---------------------------------
useImageSize
--------------------------------- */

import { useMediaQuery } from '@uidotdev/usehooks'

export function useImageSize() {
  const isMD = useMediaQuery('only screen and (min-width: 768px)')
  const isLG = useMediaQuery('only screen and (min-width: 1024px)')
  const isXL = useMediaQuery('only screen and (min-width: 1440px)')
  const isXXL = useMediaQuery('only screen and (min-width: 1920px)')

  function getImageSize() {
    if (isXL) return { width: 600, height: 600 }
    // TODO else if…

    return {
      width: 343,
      height: 333,
    }
  }

  return {
    image: getImageSize(),
    queries: {
      isMD,
      isLG,
      isXL,
      isXXL,
    },
  }
}
