/* ---------------------------------
Testimonial
--------------------------------- */

import classNames from 'classnames'

export default function Testimonial({
  title,
  subtitle,
  image,
  className,
  altTxt = 'Immagine del testimonial',
}) {
  return (
    <div className={classNames('Testimonial', className)}>
      <div className='Testimonial__image'>
        <img
          src={image}
          alt={altTxt}
        />
      </div>

      <div className='Testimonial__info'>
        {title ? (
          <span className='Testimonial__info__title'>{title}</span>
        ) : null}

        {subtitle ? (
          <span className='Testimonial__info__subtitle'>{subtitle}</span>
        ) : null}
      </div>
    </div>
  )
}
