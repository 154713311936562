export const COUNTRIES = [
  { label: 'Afghanistan', id: 'AF' },
  { label: 'Albania', id: 'AL' },
  { label: 'Algeria', id: 'DZ' },
  { label: 'Andorra', id: 'AD' },
  { label: 'Angola', id: 'AO' },
  { label: 'Anguilla', id: 'AI' },
  { label: 'Antartide', id: 'AQ' },
  { label: 'Antigua e Barbuda', id: 'AG' },
  { label: 'Antille Olandesi', id: 'AN' },
  { label: 'Arabia Saudita', id: 'SA' },
  { label: 'Argentina', id: 'AR' },
  { label: 'Armenia', id: 'AM' },
  { label: 'Aruba', id: 'AW' },
  { label: 'Australia', id: 'AU' },
  { label: 'Austria', id: 'AT' },
  { label: 'Azerbaijan', id: 'AZ' },
  { label: 'Bahamas', id: 'BS' },
  { label: 'Bahrain', id: 'BH' },
  { label: 'Bangladesh', id: 'BD' },
  { label: 'Barbados', id: 'BB' },
  { label: 'Belgio', id: 'BE' },
  { label: 'Belize', id: 'BZ' },
  { label: 'Benin', id: 'BJ' },
  { label: 'Bermuda', id: 'BM' },
  { label: 'Bielorussia', id: 'BY' },
  { label: 'Bhutan', id: 'BT' },
  { label: 'Bolivia', id: 'BO' },
  { label: 'Bosnia Erzegovina', id: 'BA' },
  { label: 'Botswana', id: 'BW' },
  { label: 'Brasile', id: 'BR' },
  { label: 'Brunei Darussalam', id: 'BN' },
  { label: 'Bulgaria', id: 'BG' },
  { label: 'Burkina Faso', id: 'BF' },
  { label: 'Burundi', id: 'BI' },
  { label: 'Cambogia', id: 'KH' },
  { label: 'Camerun', id: 'CM' },
  { label: 'Canada', id: 'CA' },
  { label: 'Capo Verde', id: 'CV' },
  { label: 'Ciad', id: 'TD' },
  { label: 'Cile', id: 'CL' },
  { label: 'Cina', id: 'CN' },
  { label: 'Cipro', id: 'CY' },
  { label: 'Citta del Vaticano', id: 'VA' },
  { label: 'Colombia', id: 'CO' },
  { label: 'Comore', id: 'KM' },
  { label: 'Corea del Nord', id: 'KP' },
  { label: 'Corea del Sud', id: 'KR' },
  { label: 'Costa Rica', id: 'CR' },
  { label: "Costa d'Avorio", id: 'CI' },
  { label: 'Croazia', id: 'HR' },
  { label: 'Cuba', id: 'CU' },
  { label: 'Danimarca', id: 'DK' },
  { label: 'Dominica', id: 'DM' },
  { label: 'Ecuador', id: 'EC' },
  { label: 'Egitto', id: 'EG' },
  { label: 'El Salvador', id: 'SV' },
  { label: 'Emirati Arabi Uniti', id: 'AE' },
  { label: 'Eritrea', id: 'ER' },
  { label: 'Estonia', id: 'EE' },
  { label: 'Etiopia', id: 'ET' },
  { label: 'Federazione Russa', id: 'RU' },
  { label: 'Fiji', id: 'FJ' },
  { label: 'Filippine', id: 'PH' },
  { label: 'Finlandia', id: 'FI' },
  { label: 'Francia', id: 'FR' },
  { label: 'Gabon', id: 'GA' },
  { label: 'Gambia', id: 'GM' },
  { label: 'Georgia', id: 'GE' },
  { label: 'Germania', id: 'DE' },
  { label: 'Ghana', id: 'GH' },
  { label: 'Giamaica', id: 'JM' },
  { label: 'Giappone', id: 'JP' },
  { label: 'Gibilterra', id: 'GI' },
  { label: 'Gibuti', id: 'DJ' },
  { label: 'Giordania', id: 'JO' },
  { label: 'Grecia', id: 'GR' },
  { label: 'Grenada', id: 'GD' },
  { label: 'Groenlandia', id: 'GL' },
  { label: 'Guadalupa', id: 'GP' },
  { label: 'Guam', id: 'GU' },
  { label: 'Guatemala', id: 'GT' },
  { label: 'Guinea', id: 'GN' },
  { label: 'Guinea-Bissau', id: 'GW' },
  { label: 'Guinea Equatoriale', id: 'GQ' },
  { label: 'Guyana', id: 'GY' },
  { label: 'Guyana Francese', id: 'GF' },
  { label: 'Haiti', id: 'HT' },
  { label: 'Honduras', id: 'HN' },
  { label: 'Hong Kong', id: 'HK' },
  { label: 'India', id: 'IN' },
  { label: 'Indonesia', id: 'ID' },
  { label: 'Iran', id: 'IR' },
  { label: 'Iraq', id: 'IQ' },
  { label: 'Irlanda', id: 'IE' },
  { label: 'Isola di Bouvet', id: 'BV' },
  { label: 'Isola di Natale', id: 'CX' },
  { label: 'Isola Heard e Isole McDonald', id: 'HM' },
  { label: 'Isole Cayman', id: 'KY' },
  { label: 'Isole Cocos', id: 'CC' },
  { label: 'Isole Cook', id: 'CK' },
  { label: 'Isole Falkland', id: 'FK' },
  { label: 'Isole Faroe', id: 'FO' },
  { label: 'Isole Marshall', id: 'MH' },
  { label: 'Isole Marianne Settentrionali', id: 'MP' },
  { label: "Isole Minori degli Stati Uniti d'America", id: 'UM' },
  { label: 'Isola Norfolk', id: 'NF' },
  { label: 'Isole Solomon', id: 'SB' },
  { label: 'Isole Turks e Caicos', id: 'TC' },
  { label: 'Isole Vergini Americane', id: 'VI' },
  { label: 'Isole Vergini Britanniche', id: 'VG' },
  { label: 'Israele', id: 'IL' },
  { label: 'Islanda', id: 'IS' },
  { label: 'Italia', id: 'IT' },
  { label: 'Kazakhistan', id: 'KZ' },
  { label: 'Kenya', id: 'KE' },
  { label: 'Kirghizistan', id: 'KG' },
  { label: 'Kiribati', id: 'KI' },
  { label: 'Kuwait', id: 'KW' },
  { label: 'Laos', id: 'LA' },
  { label: 'Lettonia', id: 'LV' },
  { label: 'Lesotho', id: 'LS' },
  { label: 'Libano', id: 'LB' },
  { label: 'Liberia', id: 'LR' },
  { label: 'Libia', id: 'LY' },
  { label: 'Liechtenstein', id: 'LI' },
  { label: 'Lituania', id: 'LT' },
  { label: 'Lussemburgo', id: 'LU' },
  { label: 'Macao', id: 'MO' },
  { label: 'Macedonia', id: 'MK' },
  { label: 'Madagascar', id: 'MG' },
  { label: 'Malawi', id: 'MW' },
  { label: 'Maldive', id: 'MV' },
  { label: 'Malesia', id: 'MY' },
  { label: 'Mali', id: 'ML' },
  { label: 'Malta', id: 'MT' },
  { label: 'Marocco', id: 'MA' },
  { label: 'Martinica', id: 'MQ' },
  { label: 'Mauritania', id: 'MR' },
  { label: 'Maurizius', id: 'MU' },
  { label: 'Mayotte', id: 'YT' },
  { label: 'Messico', id: 'MX' },
  { label: 'Moldavia', id: 'MD' },
  { label: 'Monaco', id: 'MC' },
  { label: 'Mongolia', id: 'MN' },
  { label: 'Montserrat', id: 'MS' },
  { label: 'Mozambico', id: 'MZ' },
  { label: 'Myanmar', id: 'MM' },
  { label: 'Namibia', id: 'NA' },
  { label: 'Nauru', id: 'NR' },
  { label: 'Nepal', id: 'NP' },
  { label: 'Nicaragua', id: 'NI' },
  { label: 'Niger', id: 'NE' },
  { label: 'Nigeria', id: 'NG' },
  { label: 'Niue', id: 'NU' },
  { label: 'Norvegia', id: 'NO' },
  { label: 'Nuova Caledonia', id: 'NC' },
  { label: 'Nuova Zelanda', id: 'NZ' },
  { label: 'Oman', id: 'OM' },
  { label: 'Paesi Bassi', id: 'NL' },
  { label: 'Pakistan', id: 'PK' },
  { label: 'Palau', id: 'PW' },
  { label: 'Panama', id: 'PA' },
  { label: 'Papua Nuova Guinea', id: 'PG' },
  { label: 'Paraguay', id: 'PY' },
  { label: 'Peru', id: 'PE' },
  { label: 'Pitcairn', id: 'PN' },
  { label: 'Polinesia Francese', id: 'PF' },
  { label: 'Polonia', id: 'PL' },
  { label: 'Portogallo', id: 'PT' },
  { label: 'Porto Rico', id: 'PR' },
  { label: 'Qatar', id: 'QA' },
  { label: 'Regno Unito', id: 'GB' },
  { label: 'Repubblica Ceca', id: 'CZ' },
  { label: 'Repubblica Centroafricana', id: 'CF' },
  { label: 'Repubblica del Congo', id: 'CG' },
  { label: 'Repubblica Democratica del Congo', id: 'CD' },
  { label: 'Repubblica Dominicana', id: 'DO' },
  { label: 'Reunion', id: 'RE' },
  { label: 'Romania', id: 'RO' },
  { label: 'Ruanda', id: 'RW' },
  { label: 'Sahara Occidentale', id: 'EH' },
  { label: 'Saint Kitts e Nevis', id: 'KN' },
  { label: 'Saint Pierre e Miquelon', id: 'PM' },
  { label: 'Saint Vincent e Grenadine', id: 'VC' },
  { label: 'Samoa', id: 'WS' },
  { label: 'Samoa Americane', id: 'AS' },
  { label: 'San Marino', id: 'SM' },
  { label: "Sant'Elena", id: 'SH' },
  { label: 'Santa Lucia', id: 'LC' },
  { label: 'Sao Tome e Principe', id: 'ST' },
  { label: 'Senegal', id: 'SN' },
  { label: 'Kosovo', id: 'XK' },
  { label: 'Seychelles', id: 'SC' },
  { label: 'Sierra Leone', id: 'SL' },
  { label: 'Singapore', id: 'SG' },
  { label: 'Siria', id: 'SY' },
  { label: 'Slovacchia', id: 'SK' },
  { label: 'Slovenia', id: 'SI' },
  { label: 'Somalia', id: 'SO' },
  { label: 'Spagna', id: 'ES' },
  { label: 'Sri Lanka', id: 'LK' },
  { label: 'Stati Federati della Micronesia', id: 'FM' },
  { label: "Stati Uniti d'America", id: 'US' },
  { label: 'Sud Africa', id: 'ZA' },
  { label: 'Sud Georgia e Isole Sandwich', id: 'GS' },
  { label: 'Sudan', id: 'SD' },
  { label: 'Suriname', id: 'SR' },
  { label: 'Svalbard e Jan Mayen', id: 'SJ' },
  { label: 'Svezia', id: 'SE' },
  { label: 'Svizzera', id: 'CH' },
  { label: 'Swaziland', id: 'SZ' },
  { label: 'Tagikistan', id: 'TJ' },
  { label: 'Tailandia', id: 'TH' },
  { label: 'Taiwan', id: 'TW' },
  { label: 'Tanzania', id: 'TZ' },
  { label: "Territori Britannici dell'Oceano Indiano", id: 'IO' },
  { label: 'Territori Francesi del Sud', id: 'TF' },
  { label: 'Territori Palestinesi Occupati', id: 'PS' },
  { label: 'Timor Est', id: 'TL' },
  { label: 'Togo', id: 'TG' },
  { label: 'Tokelau', id: 'TK' },
  { label: 'Tonga', id: 'TO' },
  { label: 'Trinidad e Tobago', id: 'TT' },
  { label: 'Tunisia', id: 'TN' },
  { label: 'Turchia', id: 'TR' },
  { label: 'Turkmenistan', id: 'TM' },
  { label: 'Tuvalu', id: 'TV' },
  { label: 'Ucraina', id: 'UA' },
  { label: 'Uganda', id: 'UG' },
  { label: 'Ungheria', id: 'HU' },
  { label: 'Uruguay', id: 'UY' },
  { label: 'Uzbekistan', id: 'UZ' },
  { label: 'Vanuatu', id: 'VU' },
  { label: 'Venezuela', id: 'VE' },
  { label: 'Vietnam', id: 'VN' },
  { label: 'Wallis e Futuna', id: 'WF' },
  { label: 'Yemen', id: 'YE' },
  { label: 'Zambia', id: 'ZM' },
  { label: 'Zimbabwe', id: 'ZW' },
  { label: 'Serbia', id: 'RS' },
  { label: 'Montenegro', id: 'ME' },
  { label: 'Timor Est', id: 'TP' },
  { label: 'Guernsey', id: 'GG' },
]

export const FACEBOOK_URL = 'https://www.facebook.com/AIRC.it'
export const X_URL = 'https://twitter.com/AIRC_it'
export const YOUTUBE_URL = 'http://www.youtube.com/c/AIRC_ita'
export const INSTAGRAM_URL = 'https://www.instagram.com/airc.it/'
export const LINKEDIN_URL =
  'https://www.linkedin.com/company/airc-fondazione-per-la-ricerca-sul-cancro/'
export const TIKTOK_URL = 'https://www.tiktok.com/@airc.it'
export const WHATSAPP_URL =
  'https://whatsapp.com/channel/0029Va6i0YoDjiOlMzXf8q0v'
