'use client'

/* ---------------------------------
Paragraph
--------------------------------- */

import classNames from 'classnames'
import { extractStyleProps } from 'utils/styleProps'
import { motion } from 'framer-motion'

export default function Paragraph({
  children,
  negative,
  className,
  ...styleProps
}) {
  return (
    <motion.p
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={classNames(
        'Paragraph',
        className,
        negative && `Paragraph--negative`,
        extractStyleProps(styleProps)
      )}
    >
      {children}
    </motion.p>
  )
}
