/* ---------------------------------
TestimonialCard
--------------------------------- */

import { Callout, Testimonial } from 'components/atoms'
import Card from '../../atoms/Card'

export default function TestimonialCard({ children, ...testimonial }) {
  return (
    <Card className={'TestimonialCard'}>
      <Testimonial
        className={'TestimonialCard__testimonial'}
        {...testimonial}
      />

      <Callout className={'TestimonialCard__callout'}>{children}</Callout>
    </Card>
  )
}
