'use client'

/* ---------------------------------
ProgressCard
--------------------------------- */

import { Card, ProgressBar } from 'components/atoms'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export default function ProgressCard({
  figures,
  testimonials,
  ctaMessage,
  className,
}) {
  return (
    <Card className={classNames('ProgressCard', className)}>
      {figures ? (
        <div className='ProgressCard__figures'>
          <span className='ProgressCard__figures__part'>{figures.part}</span>

          <span className='ProgressCard__figures__whole'>/{figures.whole}</span>

          <span className='ProgressCard__figures__unit'>
            {' '}
            {figures.unit} raccolti
          </span>
        </div>
      ) : null}

      {figures ? (
        <div className='ProgressCard__progress'>
          <ProgressBar
            progress={{ part: figures.part, whole: figures.whole }}
          />
        </div>
      ) : null}

      {testimonials ? (
        <div className='ProgressCard__testimonials'>{testimonials}</div>
      ) : null}

      {ctaMessage ? (
        <div className='ProgressCard__cta-message'>{ctaMessage}</div>
      ) : null}
    </Card>
  )
}

ProgressCard.propTypes = {
  figures: PropTypes.shape({
    part: PropTypes.number.isRequired,
    whole: PropTypes.number.isRequired,
    unit: PropTypes.string.isRequired,
  }).isRequired,
  testimonials: PropTypes.node,
  ctaMessage: PropTypes.string,
}
