'use client'
import { useEffect, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { IoCloseOutline, IoSparklesSharp } from 'react-icons/io5'
import TaxCodeCalculatorModal from '../Modal/TaxCodeCalculatorModal'

const valuePicker = (value, defaultValue, formValue, useForm) => {
  const isValidValue = (value) => {
    return value !== undefined && value !== null && value !== ''
  }
  if (useForm && isValidValue(formValue)) {
    return formValue
  }
  if (isValidValue(value)) {
    return value
  }

  return defaultValue
}
const Input = ({
  label = 'Ricorrenza della donazione',
  classNames = {
    input: 'input',
    icon: 'input_icon',
  },
  name = 'input-default',
  defaultValue = '',
  isHidden = false,
  disableQueryState = false,
  config = null,
  placeholder = '',
  type = 'text',
  useFormContextIfAvailable = false,
  handleKeyPress = () => {},
  onChange: _onChange = null,
  onBlur: _onBlur = null,
  hasCloseIcon = false,
  onCloseIconClick = () => {},
  value: _value = null,
  className = '',
  ...rest
}) => {
  const hasRendered = useRef(false)
  const formContext = useFormContext()
  const currentFormInputValue = formContext?.getValues(name)
  const [isTaxCodeModallOpen, setIsTaxCodeModal] = useState(false)

  const [inputValue, setInputValue] = useState(
    valuePicker(
      _value,
      defaultValue,
      currentFormInputValue,
      useFormContextIfAvailable
    )
  )

  const isDatePick = type === 'date'
  const isTaxCodeIndividual = config?.pattern?.value === 'tax-code-individual'
  const isProvinceIndividual =
    name.includes('rovinc') || label.includes('rovinc')

  const handleChange = (e) => {
    const _value = e.target.value
    if (_onChange) {
      _onChange(e)
    }
    if (useFormContextIfAvailable) {
      formContext.setValue(name, _value)
    }
    setInputValue(_value)
    return _value
  }

  const handleBlur = (e) => {
    if (_onBlur) {
      _onBlur(e)
    }
  }

  const parsePattern = (pattern) => {
    switch (pattern) {
      case 'only-letters':
        return /^[a-zA-Z\s-]+$/
      case 'only-numbers':
        return /^[0-9]+$/
      case 'tax-code-individual':
        return /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/
      case 'email':
        return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      case 'address':
        return /^[a-zA-Z0-9\s,'-]*$/
      case 'cap':
        return /^[0-9]{5}$/
      case 'iban':
        return /^([A-Z]{2})(\d{2})([A-Z\d]{4,34})$/
      default:
        return null
    }
  }

  const datePickProps = isDatePick
    ? {
        min: config?.min ? config.min : '1900-01-01',
        max: config?.max ? config.max : '',
      }
    : {}

  let inputProps = {}
  const _config = config?.pattern?.value
    ? {
        ...config,
        pattern: {
          ...config.pattern,
          value: parsePattern(config.pattern.value),
        },
      }
    : config

  if (formContext && useFormContextIfAvailable) {
    const { register } = formContext
    inputProps = {
      ...datePickProps,
      ...register(name, _config),
      onChange: handleChange,
      value: inputValue,
      ...(isProvinceIndividual
        ? {
            maxLength: '2',
          }
        : {}),
    }
  } else {
    inputProps = {
      name,
      value: inputValue,
      onChange: handleChange,
      ...datePickProps,
      ...(isProvinceIndividual
        ? {
            maxLength: '2',
          }
        : {}),
    }
  }

  if (!hasRendered.current) {
    hasRendered.current = true
    if (useFormContextIfAvailable && formContext) {
      formContext.setValue(
        name,
        valuePicker(_value, defaultValue, currentFormInputValue, true)
      )
    }
  }

  useEffect(() => {
    const valuePicked = valuePicker(
      _value,
      defaultValue,
      currentFormInputValue,
      useFormContextIfAvailable
    )
    if (valuePicked !== inputValue) {
      setInputValue(valuePicked)
    }
  }, [
    _value,
    defaultValue,
    currentFormInputValue,
    useFormContextIfAvailable,
    inputValue,
  ])

  return (
    <div className={'input-wrapper'}>
      <input
        data-lpignore='true'
        className={`${classNames.input} ${
          isProvinceIndividual ? 'input_province' : ''
        } ${isTaxCodeIndividual ? 'input_tax-code' : ''} ${className}
        `}
        placeholder={placeholder}
        type={isHidden ? 'hidden' : type}
        name={name}
        onBlur={handleBlur}
        {...inputProps}
        {...rest}
      />
      {isTaxCodeIndividual ? (
        <IoSparklesSharp
          className={classNames.icon}
          onClick={() => setIsTaxCodeModal(true)}
        />
      ) : null}
      {hasCloseIcon ? (
        <IoCloseOutline
          id={'close-icon'}
          className={classNames.icon}
          onClick={() => {
            setInputValue(null)
            if (formContext) {
              formContext.setValue(name, '')
            }
            onCloseIconClick()
          }}
        />
      ) : null}
      {isTaxCodeIndividual ? (
        <TaxCodeCalculatorModal
          isOpen={isTaxCodeModallOpen}
          onClose={() => setIsTaxCodeModal(false)}
        />
      ) : null}
    </div>
  )
}

// const [inputValue, setInputValue] = useQueryState(name, {
//   defaultValue: useFormContextIfAvailable
//     ? _value || currentFormInputValue || defaultValue
//     : _value || defaultValue,
//   throttleMs: disableQueryState ? +Infinity : 200,
//   serialize: (value) => {
//     if (disableQueryState) return null
//     // const newValue = useFormContextIfAvailable ? currentFormInputValue : value
//     return value
//   },
//   parse: (value) => {
//     if (disableQueryState) return null
//     // const newValue = useFormContextIfAvailable ? currentFormInputValue : value
//     return value
//   },
// })

export default Input
