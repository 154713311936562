import {
  Checkbox,
  Input,
  InputAdder,
  RadioGroup,
  Select,
} from 'components/molecules'
import { PaymentMethods } from 'components/organism'

export const DynamicControl = ({
  inputType,
  fieldName,
  defaultValue,
  options = [],
  config = {},
  isHidden = false,
  useFormContextIfAvailable = true,
  ...rest
}) => {
  switch (inputType) {
    case 'text':
    case 'date':
    case 'email':
    case 'tel':
      return (
        <Input
          {...rest}
          type={inputType}
          label={fieldName}
          name={fieldName}
          defaultValue={defaultValue}
          isHidden={isHidden}
          config={config}
          useFormContextIfAvailable={useFormContextIfAvailable}
        />
      )
    case 'checkbox':
      return (
        <Checkbox
          {...rest}
          label={fieldName}
          name={fieldName}
          defaultValue={defaultValue}
          isHidden={isHidden}
          config={config}
          useFormContextIfAvailable={useFormContextIfAvailable}
        />
      )
    case 'select': {
      return (
        <Select
          {...rest}
          label={fieldName}
          name={fieldName}
          defaultValue={defaultValue}
          isHidden={isHidden}
          config={config}
          useFormContextIfAvailable={useFormContextIfAvailable}
        />
      )
    }
    case 'radio-group':
      return (
        <RadioGroup
          disableQueryState={rest.disableQueryState}
          radioOptions={rest.radioOptions}
          defaultValue={defaultValue}
          name={fieldName}
          isHidden={isHidden}
          config={config}
          useFormContextIfAvailable={useFormContextIfAvailable}
        />
      )
    case 'payment-method':
      return (
        <PaymentMethods
          key={fieldName}
          name={fieldName}
          isHidden={isHidden}
          config={config}
          {...rest}
        />
      )
    case 'input-adder':
      return (
        <InputAdder
          {...rest}
          key={fieldName}
          label={fieldName}
          defaultValue={defaultValue}
          name={fieldName}
          isHidden={isHidden}
          config={config}
          useFormContextIfAvailable={useFormContextIfAvailable}
        />
      )
    case 'form-content':
      switch (fieldName) {
        case 'text-content':
          return
        default:
          return <span>to be done</span>
      }

    default:
      return <span>to be done</span>
  }
}
