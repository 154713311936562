/* ---------------------------------
SliderNavigation
--------------------------------- */

import { IconButton } from 'components/atoms'
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs'

export function SliderNavigation() {
  return (
    <div className='Slider__controls__navigation'>
      <IconButton
        className={
          'Slider__controls__navigation__button Slider__controls__navigation__button--prev'
        }
      >
        {/* The size of 24px specified in the spec seems to cause jagged edges */}
        <BsChevronCompactLeft size={23} />
      </IconButton>

      <IconButton
        className={
          'Slider__controls__navigation__button Slider__controls__navigation__button--next'
        }
      >
        <BsChevronCompactRight size={23} />
      </IconButton>
    </div>
  )
}
