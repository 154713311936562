/* ---------------------------------
EditorialSection
--------------------------------- */

import {
  Button,
  Image,
  OpeningTitle,
  Paragraph,
  Section,
  Title,
} from 'components/atoms'
import { useImageSize } from '@/hooks/useImageSize'
import { TestimonialCard } from 'components/molecules'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useRouter } from 'next/navigation'

export default function EditorialSection({ data }) {
  const { attributes } = data ?? {}
  const {
    openingTitle,
    title,
    paragraph,
    image,
    ctaButton,
    testimonialCard,
    layout,
    bg: sectionBg,
    textAlign,
  } = attributes ?? {}

  const router = useRouter()

  const { image: imageSize, queries } = useImageSize()
  const { isLG, isXL } = queries

  return (
    <Section
      bg={sectionBg}
      className={classNames(
        'EditorialSection',
        layout === 'invert_cols' && 'EditorialSection--layout_invert_cols'
      )}
      pyXXL={isXL}
    >
      <div className='EditorialSection__grid'>
        <div className='EditorialSection__grid__col-1'>
          {openingTitle ? (
            <OpeningTitle
              taLeft={isLG || textAlign === 'left'}
              mbS
            >
              {openingTitle}
            </OpeningTitle>
          ) : null}

          {title ? (
            <Title
              taLeft={isLG || textAlign === 'left'}
              mt0
              mbS
              level={2}
            >
              {/* TODO sanitize */}
              <span dangerouslySetInnerHTML={{ __html: title }} />
            </Title>
          ) : null}

          {paragraph ? (
            <Paragraph
              mbL
              taLeft={isLG || textAlign === 'left'}
            >
              {paragraph}
            </Paragraph>
          ) : null}

          {testimonialCard && isLG ? (
            <div style={{ marginBottom: 'var(--Spacing-M)' }}>
              <TestimonialCard
                title={testimonialCard.data?.attributes?.title}
                subtitle={testimonialCard.data?.attributes?.subtitle}
                image={testimonialCard.data?.attributes?.image?.url}
              >
                {testimonialCard.data?.attributes?.text}
              </TestimonialCard>
            </div>
          ) : null}

          {ctaButton ? (
            <Button
              className={'EditorialSection__cta-button'}
              variant={'cta'}
              // TODO what if it's a fully qualified URL
              onClick={() => router.push(ctaButton.url)}
            >
              {ctaButton.label}
            </Button>
          ) : null}
        </div>

        <div className='EditorialSection__grid__col-2'>
          {image ? (
            <Image
              styleProps={{ mbL: Boolean(testimonialCard) }}
              className={'EditorialSection__image'}
              // ignoring the mock value for a dynamic mock
              src={`https://picsum.photos/${Object.values(imageSize)
                .join()
                .replace(',', '/')}`}
              alt={image?.alt}
              layout='responsive'
              {...imageSize}
            />
          ) : null}

          {testimonialCard && !isLG ? (
            <TestimonialCard
              title={testimonialCard.data?.attributes?.title}
              subtitle={testimonialCard.data?.attributes?.subtitle}
              image={testimonialCard.data?.attributes?.image?.url}
            >
              {testimonialCard.data?.attributes?.text}
            </TestimonialCard>
          ) : null}
        </div>
      </div>
    </Section>
  )
}

EditorialSection.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    attributes: PropTypes.shape({
      // this would represent a CMS enumeration
      layout: PropTypes.oneOf(['invert_cols', 'single_col']),
      bg: PropTypes.oneOf([
        'dark',
        'black',
        'blue',
        'dark-blue',
        'dark-green',
        'green',
        'grey-050',
        'grey-100',
        'grey-200',
        'grey-300',
        'grey-500',
        'grey-700',
        'grey-900',
        'rich-blue',
        'white',
      ]),
      textAlign: PropTypes.oneOf(['left', 'center']),
      openingTitle: PropTypes.string,
      title: PropTypes.string,
      paragraph: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        alt: PropTypes.string,
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
      }),
      ctaButton: PropTypes.shape({
        label: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
      testimonialCard: PropTypes.shape({
        data: PropTypes.shape({
          id: PropTypes.number,
          attributes: PropTypes.shape({
            title: PropTypes.string,
            subtitle: PropTypes.string,
            image: PropTypes.shape({
              url: PropTypes.string,
            }),
            text: PropTypes.string,
            createdAt: PropTypes.string,
            updatedAt: PropTypes.string,
            publishedAt: PropTypes.string,
          }),
        }),
      }),
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      publishedAt: PropTypes.string,
    }),
  }).isRequired,
}
