/* ---------------------------------
ProgressStep
--------------------------------- */

import classNames from 'classnames'

export default function ProgressStep({ active, className }) {
  return (
    <span
      className={classNames(
        'ProgressStep',
        className,
        active && 'ProgressStep--active'
      )}
    />
  )
}
