'use client'

/* ---------------------------------
DonateSection
--------------------------------- */

import {
  Anchor,
  Avatar,
  Button,
  OpeningTitle,
  Paragraph,
  Section,
  Title,
} from 'components/atoms'
import { ProgressCard } from 'components/molecules'
import { useMediaQuery } from '@uidotdev/usehooks'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useRouter } from 'next/navigation'

export default function DonateSection({
  data,
  // TODO move to mock
  progressCard = {
    figures: {
      part: 6500,
      whole: 10000,
      unit: '€',
    },
    testimonials: (
      <Avatar
        variant={'embedded'}
        numberOfAvatar={3}
      />
    ),
    ctaMessage: '+1000 hanno già donato',
  },
}) {
  const { attributes } = data ?? {}
  const {
    openingTitle,
    title,
    paragraph,
    ctaButton,
    // progressCard,
    layout,
    bg: sectionBg,
    textAlign,
  } = attributes ?? {}

  const router = useRouter()

  const isMD = useMediaQuery('only screen and (min-width: 768px)')
  const isLG = useMediaQuery('only screen and (min-width: 1024px)')
  const isXL = useMediaQuery('only screen and (min-width: 1440px)')
  const isXXL = useMediaQuery('only screen and (min-width: 1920px)')

  return (
    <Section
      bg={sectionBg}
      pyXL
      pyXXL={isXL}
      className={classNames(
        'DonateSection',
        layout === 'invert_cols' && 'DonateSection--layout_invert_cols'
      )}
    >
      <div className='DonateSection__grid'>
        <div className='DonateSection__grid__col-1'>
          {openingTitle ? (
            <OpeningTitle
              negative
              taLeft={isLG || textAlign === 'left'}
              mbS
            >
              {openingTitle}
            </OpeningTitle>
          ) : null}

          {title ? (
            <Title
              negative
              taLeft={isLG || textAlign === 'left'}
              mt0
              mbS
              level={2}
            >
              {/* TODO sanitize */}
              <span dangerouslySetInnerHTML={{ __html: title }} />
            </Title>
          ) : null}

          {paragraph ? (
            <Paragraph
              negative
              mbS
              taLeft={isLG || textAlign === 'left'}
            >
              {paragraph}
            </Paragraph>
          ) : null}

          {ctaButton ? (
            isLG ? (
              <Button
                mtXXS
                className={'DonateSection__cta-button'}
                variant={'cta'}
                // TODO what if it's a fully qualified URL
                onClick={() => router.push(ctaButton.url)}
              >
                {ctaButton.label}
              </Button>
            ) : (
              <Anchor
                styleProps={{
                  dBlock: true,
                  mbL: true,
                  taCenter: !isLG && textAlign === 'center',
                }}
                href={ctaButton.url}
              >
                {ctaButton.label}
              </Anchor>
            )
          ) : null}
        </div>

        <div className='DonateSection__grid__col-2'>
          {progressCard ? (
            <ProgressCard
              figures={progressCard?.figures}
              testimonials={progressCard?.testimonials}
              ctaMessage={progressCard?.ctaMessage}
            />
          ) : null}
        </div>
      </div>
    </Section>
  )
}

DonateSection.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    attributes: PropTypes.shape({
      // this would represent a CMS enumeration
      layout: PropTypes.oneOf(['invert_cols', 'single_col']),
      bg: PropTypes.oneOf([
        'dark',
        'black',
        'blue',
        'dark-blue',
        'dark-green',
        'green',
        'grey-050',
        'grey-100',
        'grey-200',
        'grey-300',
        'grey-500',
        'grey-700',
        'grey-900',
        'rich-blue',
        'white',
      ]),
      textAlign: PropTypes.oneOf(['left', 'center']),
      openingTitle: PropTypes.string,
      title: PropTypes.string,
      paragraph: PropTypes.string,
      ctaButton: PropTypes.shape({
        label: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
      progressCard: PropTypes.shape({
        data: PropTypes.shape({
          id: PropTypes.number,
          attributes: PropTypes.shape({
            figures: PropTypes.shape({
              part: PropTypes.number.isRequired,
              whole: PropTypes.number.isRequired,
              unit: PropTypes.string.isRequired,
            }).isRequired,
            testimonials: PropTypes.node,
            ctaMessage: PropTypes.string,
            createdAt: PropTypes.string,
            updatedAt: PropTypes.string,
            publishedAt: PropTypes.string,
          }),
        }),
      }),
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      publishedAt: PropTypes.string,
    }),
  }).isRequired,
}
