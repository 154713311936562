import { getLabelToDisplay, getValueToDisplay } from './utils/functions'

function FormRecapItem({ label, value, oldValue = null, fieldName, ...rest }) {
  if (
    !value ||
    fieldName === 'donatorPaymentMethod' ||
    fieldName?.startsWith('donationLetterPartecipants-') ||
    fieldName?.startsWith('donationPaymentMethodOnRecurrenceIbanIsOwner')
  )
    return null
  const valueToDisplay = getValueToDisplay(value, rest)
  const labelToDisplay = getLabelToDisplay(label, rest)

  return (
    <div className='recap_item'>
      <span className='recap_item_label'>{labelToDisplay}</span>
      <span className='recap_item_value-wrapper'>
        {oldValue ? (
          <>
            <span className='recap_item_old-value'>
              {getValueToDisplay(oldValue, rest)}
            </span>
            <span className='recap_item_separator'>{'  '}</span>
          </>
        ) : (
          ``
        )}
        <span className='recap_item_value'>{valueToDisplay}</span>
      </span>
    </div>
  )
}

export default FormRecapItem
