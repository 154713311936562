'use client'

import { Checkbox } from '@/components/molecules'
import ErrorModal from '@/components/molecules/Modal/ErrorModal'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'
import usePaymentHandler from '../../../hooks/usePaymentHandler'
import PaypalAdvancedCheckout from '../AdvancedCheckout'

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

const privacyPolicyDisclaimer =
  'Per proseguire, è necessario accettare la privacy policy e i termini e condizioni'

const PaymentMethods = ({
  name,
  isHidden = false,
  disableQueryState = false,
  config,
  checkPrivacyBeforeSubmit = true,
  checkTermsBeforeSubmit = true,
  uiOptions = {},
  ...rest
}) => {
  const [isDelayComplete, setIsDelayComplete] = useState(false)
  const { showPrivacyAndTerms } = uiOptions
  const { getValues, watch, setValue } = useFormContext()
  const {
    token,
    paymentOptions,
    paypalInitialOptions,
    paypalClientId,
    approvalLink,
    setApprovalLink,
    handleOnCreateOrder: _handleOnCreateOrder,
    handleOnCreateAlternativeOrder: _handleOnCreateAlternativeOrder,
    handleOnApprove: _handleOnApprove,
    handleOnError: _handleOnError,
    paymentState,
    setPaymentState,
    modalErrorIsOpen,
    setModalErrorIsOpen,
  } = usePaymentHandler({
    dataToSend: { ...getValues() },
  })
  const excludedPaymentMethods = [paymentOptions?.['disable-funding']] ?? []

  const [paymentApiResponse, updatePaymentApiResponse] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [privacyValue, setPrivacyValue] = useState(
    Boolean(watch('privacy') || false)
  )
  const isPrivacyChecked = privacyValue === 'true' || privacyValue === true

  const isLoading =
    !token || !paypalClientId || !paymentOptions || !isDelayComplete

  const notifyOnPrivacyUnchecked = () => {
    toast.warn(
      'Devi prima accettare privacy policy e i termini e condizioni',
      {
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      },
      { delay: 500 }
    )
  }

  const updateChosenPaymentMethodFormValue = (paymentMethod) =>
    setValue(name, paymentMethod)

  const handleOnCreateOrder = async (data) => {
    if (isPrivacyChecked !== true) {
      notifyOnPrivacyUnchecked()
      return
    }
    const { paymentSource } = data
    if (!paymentSource) return
    const orderCreationResponse = await _handleOnCreateOrder(data)
    updateChosenPaymentMethodFormValue(data.paymentSource)
    updatePaymentApiResponse(orderCreationResponse)
    const { orderID } = orderCreationResponse
    return orderID
  }

  const handleAlternativeCreateOrder = async (paymentSource) => {
    if (isPrivacyChecked !== true) {
      notifyOnPrivacyUnchecked()
      return
    }
    if (!paymentSource) return
    updateChosenPaymentMethodFormValue(paymentSource)
    const innerApproveResponse =
      await _handleOnCreateAlternativeOrder(paymentSource)
    setIsModalOpen(true)
    return innerApproveResponse
  }

  const handleOnApprove = async (data) => {
    if (isPrivacyChecked !== true) {
      notifyOnPrivacyUnchecked()
      return
    }
    const innerApproveResponse = await _handleOnApprove({
      ...data,
      ...paymentApiResponse,
    })
    return innerApproveResponse
  }

  const handleOnError = async (data) => {
    if (isPrivacyChecked !== true) {
      notifyOnPrivacyUnchecked()
      return
    }
    const innerErrorResponse = await _handleOnError(data)
    return innerErrorResponse
  }

  const handleSelectCreditCard = (paymentMethod) =>
    updateChosenPaymentMethodFormValue(paymentMethod)

  // if (!token || !paypalClientId || !paymentOptions || true) {
  //   return <Spinner />
  // }
  //
  useEffect(() => {
    const initDelay = async () => {
      await delay(2000)
      setIsDelayComplete(true)
    }
    initDelay()
  }, [])

  return (
    <div className='payment-methods'>
      <ErrorModal
        isOpen={modalErrorIsOpen}
        onClose={() => setModalErrorIsOpen(false)}
        copy={{
          title: 'Ops qualcosa è andato storto',
          body: 'Si è verificato un errore durante il pagamento, ritenta.',
          cta: 'Chiudi',
        }}
      />
      {showPrivacyAndTerms ? (
        <Checkbox
          name='privacy'
          content={'ciao'}
          useFormContextIfAvailable={true}
          onChange={(e) => setPrivacyValue((prev) => !prev)}
        />
      ) : null}
      <PaypalAdvancedCheckout
        paypalInitialOptions={paypalInitialOptions}
        excludedPaymentMethods={excludedPaymentMethods}
        onCreateOrder={handleOnCreateOrder}
        onAlternativeCreateOrder={handleAlternativeCreateOrder}
        onApprove={handleOnApprove}
        onError={handleOnError}
        isLoading={isLoading}
        isDisabled={isPrivacyChecked === true ? false : true}
        paymentState={paymentState}
        setPaymentState={setPaymentState}
        onSelectCreditCard={handleSelectCreditCard}
      />
    </div>
  )
}
// const [chosenPaymentMethod, setChosenPaymentMethod] = useQueryState(name, {
//   throttleMs: disableQueryState ? +Infinity : 100,
// })

export default PaymentMethods
