/* ---------------------------------
ProgressBar
--------------------------------- */

export default function ProgressBar({ progress }) {
  const { part, whole } = progress ?? {}

  function getProgress(part, whole) {
    if (whole === 0) {
      return 0
    }

    const percentage = (part / whole) * 100
    return Math.floor(Math.min(percentage, 100))
  }

  if (typeof part === 'undefined' || typeof whole === 'undefined') {
    return null
  }

  return (
    <span className={'ProgressBar'}>
      <span
        className='ProgressBar__progress'
        style={{ width: getProgress(part, whole) + '%' }}
      />
    </span>
  )
}
