/* ---------------------------------
Callout
--------------------------------- */

import classNames from 'classnames'

export default function Callout({ children, className }) {
  return (
    <div className={classNames('Callout', className)}>
      <p className='Callout__content'>{children}</p>
    </div>
  )
}
