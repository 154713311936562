/* ---------------------------------
VideoCard
--------------------------------- */

import { Card, PlayButton } from 'components/atoms'
import classNames from 'classnames'
import { extractStyleProps } from 'utils/styleProps'

// BIG TODO
export default function VideoCard({ video, ...styleProps }) {
  const { src, poster } = video ?? {}

  return (
    <Card
      variant={'bare'}
      className={classNames('VideoCard', extractStyleProps(styleProps))}
    >
      <div className='VideoCard__container'>
        <PlayButton className={'VideoCard__play-button'} />
        <video {...{ src, poster }}></video>
      </div>
    </Card>
  )
}
