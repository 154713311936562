/* ---------------------------------
Section
--------------------------------- */

import classNames from 'classnames'
import { Container } from 'components/atoms'
import { extractStyleProps } from 'utils/styleProps'
import PropTypes from 'prop-types'

export default function Section({
  children,
  bg,
  className,
  containerStyleProps,
  ...styleProps
}) {
  return (
    <section
      className={classNames(
        'Section',
        className,
        bg === 'dark' && 'Section--bg_dark',
        bg === 'black' && 'Section--bg_black',
        bg === 'blue' && 'Section--bg_blue',
        bg === 'dark-blue' && 'Section--bg_dark-blue',
        bg === 'dark-green' && 'Section--bg_dark-green',
        bg === 'green' && 'Section--bg_green',
        bg === 'grey-050' && 'Section--bg_grey-050',
        bg === 'grey-100' && 'Section--bg_grey-100',
        bg === 'grey-200' && 'Section--bg_grey-200',
        bg === 'grey-300' && 'Section--bg_grey-300',
        bg === 'grey-500' && 'Section--bg_grey-500',
        bg === 'grey-700' && 'Section--bg_grey-700',
        bg === 'grey-900' && 'Section--bg_grey-900',
        bg === 'rich-blue' && 'Section--bg_rich-blue',
        bg === 'white' && 'Section--bg_white',
        extractStyleProps(styleProps)
      )}
    >
      <Container
        className={classNames(
          'Section__wrapper',
          extractStyleProps(containerStyleProps)
        )}
      >
        {children}
      </Container>
    </section>
  )
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
  bg: PropTypes.oneOf([
    'dark',
    'black',
    'blue',
    'dark-blue',
    'dark-green',
    'green',
    'grey-050',
    'grey-100',
    'grey-200',
    'grey-300',
    'grey-500',
    'grey-700',
    'grey-900',
    'rich-blue',
    'white',
  ]),
  className: PropTypes.string,
  containerStyleProps: PropTypes.object,
  styleProps: PropTypes.object,
}
