import PaypalAdvancedCheckout from './AdvancedCheckout'
import PaymentMethods from './PaymentMethods'
import HeroSection from './HeroSection'
import CampaignSection from './CampaignSection'
import DonorStoriesSection from './DonorStoriesSection'
import EditorialSection from './EditorialSection'
import DonateSection from './DonateSection'
import ProgressSection from './ProgressSection'
import StoriesSection from './StoriesSection'
import Header from './Header'
import Footer from './Footer'

export {
  PaymentMethods,
  PaypalAdvancedCheckout,
  HeroSection,
  CampaignSection,
  DonateSection,
  DonorStoriesSection,
  EditorialSection,
  ProgressSection,
  StoriesSection,
  Header,
  Footer,
}
