/* ---------------------------------
ImageCard
--------------------------------- */

import { Card } from 'components/atoms'
import classNames from 'classnames'
import { extractStyleProps } from 'utils/styleProps'

export default function ImageCard({
  title,
  body,
  cta,
  image,
  className,
  ...styleProps
}) {
  return (
    <Card
      variant={'bare'}
      className={classNames(
        'ImageCard',
        className,
        extractStyleProps(styleProps)
      )}
    >
      <div
        className='ImageCard__container'
        style={{
          background: `
          linear-gradient(0deg, rgba(18, 18, 18, 0.15) 0%, rgba(18, 18, 18, 0.15) 100%),
          linear-gradient(180deg, rgba(14, 44, 55, 0.00) 0%, #0E2C37 100%),
          url("${image}") center / cover no-repeat
          `,
        }}
      >
        <div className='ImageCard__content'>
          {title ? (
            <span className={'ImageCard__content__title'}>{title}</span>
          ) : null}

          {body ? <p className={'ImageCard__content__body'}>{body}</p> : null}

          {cta ? <div className={'ImageCard__content__cta'}>{cta}</div> : null}
        </div>
      </div>
    </Card>
  )
}
