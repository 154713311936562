import { useLocalStorageState } from 'ahooks'
import { useRouter } from 'next/navigation'
import { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import axiosInstance from '../utils/axiosWrapper'
import { SERVER_PAYMENTS_METHODS } from '../utils/types'

const fetchOptionsPayments = async () => {
  try {
    const response = await axiosInstance.get('/api/options/payments')
    const options = response.data.data ?? {}
    const isLegacyDonation = options.donation === 'old'
    const excludedPaymentMethods = []
    const hasDisclaimer =
      options.disclaimer !== '' &&
      options.disclaimer !== null &&
      options.disclaimer !== undefined
    if (isLegacyDonation) {
      console.error('[❌ Error: Deprecated Donation is not supported yet]')
    }
    const propsToCheck = [
      'creditcard-activation',
      'paypal-activation',
      'postepay-activation',
      'mybank-activation',
      'satispay-activation',
    ]
    propsToCheck.forEach((prop) => {
      if (options[prop] && options[prop] === 'false') {
        excludedPaymentMethods.push(prop)
      }
    })
    return {
      ...options,
      isLegacyDonation,
      hasDisclaimer,
      excludedPaymentMethods,
    }
  } catch (error) {
    console.error('Error fetching payment options:', error)
    return {}
  }
}

const fetchPaymentsToken = async () => {
  try {
    const response = await axiosInstance.get('/api/payments/token')
    const clientToken = response.data.client_token ?? ''
    return clientToken
  } catch (error) {
    console.error('Error fetching payment token:', error)
    return ''
  }
}

const usePaymentHandler = ({ dataToSend }) => {
  const orderRef = useRef(null)
  const [token, setToken] = useState(null)
  const [paymentOptions, setPaymentOptions] = useState(null)
  const [data, setData] = useState(dataToSend)
  const [approvalLink, setApprovalLink] = useState('')
  const [modalErrorIsOpen, setModalErrorIsOpen] = useState(false)
  const [paymentState, setPaymentState] = useLocalStorageState('paymentState', {
    listenStorageChange: true,
    defaultValue: {
      state: 'IDLE',
      info: {},
    },
  })
  const router = useRouter()
  const hasRendered = useRef(false)

  const paymentClientId = process.env.paymentClientId
  const paypalClientId = process.env.paypalClientId

  const paypalInitialOptions = {
    'client-id': paypalClientId,
    currency: 'EUR',
    intent: 'capture',
    'data-client-token': token,
    dataClientToken: token,
    components: ['hosted-fields', 'buttons', 'marks', 'funding-eligibility'],
    'disable-funding': 'mybank',
  }

  const fetchPaymentData = async () => {
    try {
      const paypalToken = await fetchPaymentsToken()
      const paymentsOptions = await fetchOptionsPayments()

      setToken(paypalToken)
      setPaymentOptions(paymentsOptions)
    } catch (error) {
      console.error('Error fetchPaymentData:', error)
      return error
    }
    return
  }

  const _handleOrderRequest = async ({ paymentSource }) => {
    const serverPaymentMethod =
      paymentSource === 'creditcard' ? 'creditcard-2022' : paymentSource
    const _dataToSend = formatDataToSend(
      dataToSend,
      paymentClientId,
      serverPaymentMethod
    )
    console.log('Data to send:', _dataToSend)
    try {
      const response = await axiosInstance.post(
        '/api/payments/pay',
        _dataToSend
      )

      const {
        data: {
          data: { id_order, ckey, token, approval_link, ...rest },
        },
      } = response

      return {
        success: true,
        orderID: id_order,
        ckey,
        token,
        approvalLink: approval_link,
        ...rest,
      }
    } catch (error) {
      console.error('Error making payment request:', error)
      notifyOnPaymentError(error)
      return {
        success: false,
        error,
      }
    }
  }

  const notifyOnPaymentError = (error) => {
    toast.error(
      'Errore durante il pagamento, ritenta',
      {
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      },
      { delay: 500 }
    )
  }

  const _handleConfirm = async ({ ckey, toke, ...rest }) => {
    // could handle redirect here
    console.log('Confirming order:', ckey, toke, rest)
    return {
      success: true,
      ...rest,
    }
  }

  const handleOnCreateOrder = async (data) => {
    const { paymentSource, returnLink = false } = data
    const method = paymentSource ? paymentSource : 'creditcard'
    const orderResponse = await _handleOrderRequest({ paymentSource: method })
    if (!orderResponse.success) {
      return orderResponse
    }
    orderRef.current = orderResponse
    const { orderID, ckey, token, approvalLink, ...rest } = orderResponse

    console.log('Order response:', orderResponse)
    setPaymentState({
      state: 'WAITING_FOR_PAYMENT',
      method,
      info: {
        ...orderResponse,
      },
    })

    if (returnLink) {
      return Promise.resolve({ approvalLink })
    }
    return orderResponse
  }

  const handleOnCreateAlternativeOrder = async (paymentSource) => {
    const orderResponse = await _handleOrderRequest({
      paymentSource,
      returnLink: true,
    })
    if (!orderResponse.success) {
      return orderResponse
    }
    const { approvalLink } = orderResponse
    setApprovalLink(approvalLink ? approvalLink : '')
    const windowInstace = () => window.open(approvalLink, '_blank')
    setTimeout(() => {
      windowInstace()
    })
    setPaymentState({
      state: 'WAITING_FOR_PAYMENT',
      method: paymentSource,
      info: {
        ...orderResponse,
      },
    })
    orderRef.current = orderResponse
    return orderResponse
  }

  const handleOnApprove = async ({ ..._data }) => {
    const data = { ..._data, ...orderRef.current }
    const { orderID, authenticationStatus, paymentSource } = data
    const isCreditCardApproved = authenticationStatus === 'APPROVED'
    const isCreditCardDeclined =
      typeof authenticationStatus === 'string' &&
      authenticationStatus !== '' &&
      authenticationStatus !== 'APPROVED' &&
      authenticationStatus !== undefined &&
      authenticationStatus !== null
    const isPaypalApproved = orderID && paymentSource === 'paypal'
    const { ckey, token, ...rest } = data
    const confirmLink = `/dona/successo?ckey=${ckey}&token=${token}`
    const errorLink = `/dona/errore`
    const windowInstace = (link) => window.open(link, '_blank')

    if (isPaypalApproved) {
      if (!ckey || !token) {
        windowInstace(errorLink)
        return data
      }
      windowInstace(confirmLink)
    }
    if (isCreditCardApproved) {
      if (!ckey || !token) {
        windowInstace(errorLink)
        return data
      }
      windowInstace(confirmLink)
    }
    if (isCreditCardDeclined) {
      windowInstace(errorLink)
    }
    return data
  }

  const handleOnError = async (error) => {
    // handle error
    console.error('Error:', error)
    // router.push('/dona/errore')
    notifyOnPaymentError(error)
    return error
  }

  useEffect(() => {
    if (!hasRendered.current && paypalClientId && (!token || !paymentOptions)) {
      fetchPaymentData()
      setPaymentState({
        state: 'IDLE',
        method: '',
        info: {},
      })

      hasRendered.current = true
    }
  }, [paymentOptions, paypalClientId, setPaymentState, token])

  useEffect(() => {
    if (paymentState.state === 'PAYMENT_SUCCESS') {
      console.log('Payment success:', paymentState.info)
      setPaymentState({
        state: 'IDLE',
        method: '',
        info: {},
      })
      router.push('/dona/successo')
    }
    if (paymentState.state === 'PAYMENT_ERROR') {
      console.log('Payment failed:', paymentState.info)
      setModalErrorIsOpen(true)
      setPaymentState({
        state: 'IDLE',
        method: '',
        info: {},
      })
    }
    if (paymentState.state === 'WAITING_FOR_PAYMENT') {
      const timeout = setTimeout(() => {
        setPaymentState({
          state: 'IDLE',
          method: '',
          info: {},
        })
      }, 60000)
      return () => clearTimeout(timeout)
    }
  }, [paymentState, router, setPaymentState])

  return {
    token,
    paymentOptions,
    paypalInitialOptions,
    paymentClientId,
    paypalClientId,
    approvalLink,
    setApprovalLink,
    handleOnCreateOrder,
    handleOnCreateAlternativeOrder,
    handleOnApprove,
    handleOnError,
    paymentState,
    setPaymentState,
    modalErrorIsOpen,
    setModalErrorIsOpen,
  }
}
function formatDataToSend(inputData, paymentClientId, serverPaymentMethod) {
  const data = { ...inputData, donatorPaymentMethod: serverPaymentMethod }

  const fieldNameMap = {
    paymentClientId: 'client_id',
    donationType: 'donationtype',
    donationFrequency: 'frequency',
    donationAmount: 'amount',
    donatorPaymentMethod: 'payment-method',
    donatorFirstName: 'firstname',
    donatorLastName: 'lastname',
    donatorEmail: 'email',
    donatorCellphone: 'mobilephone',
    donatorTaxCode: 'fiscalcode',
    donatorGender: 'gender',
    donationInMemoryReceiverName: 'deceased',
    donationInMemoryLetterToRelatives: 'sendletter',
    donatorAddress: 'billingaddress[address]',
    donatorAddressNumber: 'billingaddress[number]',
    donatorPostalCode: 'billingaddress[postalcode]',
    donatorCity: 'billingaddress[city]',
    donatorProvince: 'billingaddress[state]',
    donatorCountry: 'billingaddress[country]',
    donatorType: 'usertype',
    donatorCompanyName: 'companyname',
    donatorCompanyTelephone: 'phone',
    donatorCompanyTaxCode: 'taxid',
    donationPaymentMethodOnRecurrenceType: 'paymentContinuslyType',
    donationPaymentMethodOnRecurrenceIbanIsOwner: 'bonificAccount',
    donationPaymentMethodOnRecurrenceIban: 'bonificIban',
    donationIbanOwnerFirstName: 'bonificFirstname',
    donationIbanOwnerLastName: 'bonificLastname',
    donationIbanOwnerTaxCode: 'bonificFiscalCode',
    termsAndConditions: 'termsandconditions',
    privacy: 'privacy',
    newsletter: 'newsletter',
    // '*': 'fixedAmount',
    // '*': 'amountType',
    // '*': 'anniversary',
    // '*": 'billingaddress[hamlet]', ?  frazione"
    // '*': 'birthdate', ?  data di nascita
    // id_campagna_competenza: campainParams.ets_cmmk || null,
    // id_segmento: campainParams.ets_sgmt || null,
    // id_soggetto: campainParams.ets_sggt || null,
    // id_tipo_soggetto: campainParams.ets_sggt_t || null,
    // id_mezzo: idMezzo
    // check fiscal code e taxid
  }

  const fieldValuesMap = {
    donationType: {
      free: 'free',
      'in-memory': 'memory',
    },
    donationAmount: {
      10: '10',
      20: '20',
      50: '50',
      'custom-number': (value) => value.split('-')[2],
    },
    donationFrequency: {
      'one-time': 'singola',
      monthly: 'continuativa',
      yearly: 'continuativa',
    },
    donationInMemoryLetterToRelatives: {
      yes: '1',
      no: '0',
    },
    donatorType: {
      individual: 'persona',
      company: 'azienda',
    },
    donatorGender: {
      default: 'M',
      M: 'M',
      F: 'F',
    },
    donatorPaymentMethod: {
      satispay: SERVER_PAYMENTS_METHODS.SATISPAY,
      postepay: SERVER_PAYMENTS_METHODS.POSTEPAY,
      paypal: SERVER_PAYMENTS_METHODS.PAYPAL,
      'credit-card': SERVER_PAYMENTS_METHODS.CREDITCARD,
    },
    privacy: {
      true: '1',
      false: '0',
    },
    termsAndConditions: {
      true: '1',
      false: '0',
    },
    newsletter: {
      true: '1',
      false: '0',
    },
  }

  const finalData = {
    client_id: paymentClientId,
    vertical: 'donation',
  }

  for (const [key, value] of Object.entries(data)) {
    const finalKey = fieldNameMap[key] || key
    const finalValue = mapValue(key, value, fieldValuesMap)
    finalData[finalKey] = finalValue
  }

  // remove empty values
  for (const [key, value] of Object.entries(finalData)) {
    if (key === 'fiscalcode' && value !== '') {
      finalData['taxid'] = value
    }
    if (value === '') {
      delete finalData[key]
    }
  }

  return finalData
}

function mapValue(key, value, fieldValuesMap) {
  const valueMapping = fieldValuesMap[key]

  if (valueMapping) {
    if (key === 'donationAmount' && value.startsWith('custom-number-')) {
      return valueMapping['custom-number'](value)
    } else if (typeof valueMapping[value] === 'function') {
      return valueMapping[value](value)
    } else if (valueMapping[value] !== undefined) {
      return valueMapping[value]
    }
  }
  return value
}

export default usePaymentHandler
