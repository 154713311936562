/* ---------------------------------
OpeningTitle
--------------------------------- */

import classNames from 'classnames'
import { extractStyleProps } from 'utils/styleProps'

export default function OpeningTitle({ children, negative, ...styleProps }) {
  return (
    <span
      className={classNames(
        'OpeningTitle',
        negative && `OpeningTitle--negative`,
        extractStyleProps(styleProps)
      )}
    >
      {children}
    </span>
  )
}
