/* ---------------------------------
HeroSection
--------------------------------- */

import {
  Avatar,
  Button,
  Emph,
  Image,
  Paragraph,
  Section,
  Title,
} from 'components/atoms'
import { ProgressCard } from 'components/molecules'
import { useImageSize } from '@/hooks/useImageSize'
import { useRouter } from 'next/navigation'

export default function HeroSection({
  progressCard = {
    figures: {
      part: 6500,
      whole: 10000,
      unit: '€',
    },
    testimonials: (
      <Avatar
        variant={'embedded'}
        numberOfAvatar={3}
      />
    ),
    ctaMessage: '+1000 hanno già donato',
  },
}) {
  const router = useRouter()

  const { image: imageSize, queries } = useImageSize()
  const { isLG, isXL } = queries

  return (
    // TODO move data to mocks
    <Section
      className={'HeroSection'}
      pyXXL={isXL}
    >
      <div className='HeroSection__grid'>
        <div className='HeroSection__grid__col-1'>
          <Avatar
            className={'HeroSection__testimonials'}
            numberOfAvatar={3}
          />

          <Title
            mtM
            mbS
            taLeft={isLG}
            level={1}
          >
            Unisciti ad oltre +<Emph>3000</Emph> donatori ogni anno
          </Title>

          <Paragraph
            taLeft={isLG}
            className={'HeroSection__paragraph'}
          >
            Contribuisci alla ricerca contro il cancro e salva milioni di
            persone ogni anno.
          </Paragraph>

          <Button
            className={'HeroSection__cta-button'}
            variant={'cta'}
            // TODO what if it's a fully qualified URL
            onClick={() => router.push('/dona')}
          >
            Dai il tuo contributo
          </Button>
        </div>

        <div className='HeroSection__grid__col-2'>
          <Image
            className={'HeroSection__image'}
            // Mock
            src={`https://picsum.photos/${Object.values(imageSize)
              .join()
              .replace(',', '/')}`}
            alt='Description of the image'
            layout='responsive'
            {...imageSize}
          />

          {isXL && progressCard ? (
            <ProgressCard
              className={'HeroSection__progress-card'}
              figures={progressCard.figures}
              testimonials={progressCard.testimonials}
              ctaMessage={progressCard.ctaMessage}
            />
          ) : null}
        </div>
      </div>
    </Section>
  )
}
