'use client'
import React from 'react'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    console.error('ErrorBoundary:', error)
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
  }
  render() {
    const { hasError } = this.state
    const {
      children,
      useFallback,
      useFnCallback,
      fnCallback,
      fallbackComponent: FallbackComponent,
    } = this.props

    if (hasError && useFallback) {
      return <FallbackComponent />
    }

    if (hasError && useFnCallback) {
      fnCallback()
      return null
    }
    return children
  }
}

export default ErrorBoundary
