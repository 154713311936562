function getDefaultValues(arr) {
  const defaultValues = {}
  arr.forEach((item) => {
    if (item.fieldName && item.defaultValue !== undefined) {
      defaultValues[item.fieldName] = item.defaultValue
    }
  })
  return defaultValues
}

// ***
const operators = {
  equals: (fieldValue, value) => fieldValue === value,
  notEquals: (fieldValue, value) => fieldValue !== value,
  includes: (fieldValue, value) =>
    Array.isArray(value) && value.includes(fieldValue),
}

function evaluateCondition(condition, values) {
  const fieldValue = values[condition.field]
  for (const [operator, value] of Object.entries(condition).filter(
    ([key]) => key !== 'field'
  )) {
    const operatorFn = operators[operator]
    if (operatorFn && operatorFn(fieldValue, value)) {
      return true
    }
  }
  return false
}

function groupFieldsByStep(fields) {
  return fields
    .filter((f) => !!f.uiOptions && !!f.uiOptions.step)
    .reduce((acc, field) => {
      const { fieldName, uiOptions } = field ?? { fieldName: '', uiOptions: {} }
      const { step } = uiOptions ?? { step: 0 }

      if (!acc[step]) {
        acc[step] = []
      }
      acc[step].push(field)
      return acc
    }, {})
}
function groupFieldsByEdit(fields, currentStep = null) {
  const fieldByEdit = fields
    .filter((f) => !!f.uiOptions && !!f.uiOptions.edit)
    .reduce((acc, field) => {
      const { fieldName, uiOptions } = field ?? { fieldName: '', uiOptions: {} }
      const { edit, step } = uiOptions ?? { edit: 0 }

      // check if the field is in the current step or less
      if (currentStep !== null) {
        if (step >= currentStep) {
          return acc
        }
      }

      if (!acc[edit]) {
        acc[edit] = []
      }
      acc[edit].push(field)
      return acc
    }, {})
  return fieldByEdit
}

function isPaymentStep(currentStep, stepConfig) {
  const step = stepConfig[currentStep]
  return (step && step.isPaymentStep) || false
}

function isRecurrencePaymentStep(currentStep, stepConfig) {
  const step = stepConfig[currentStep]
  return (step && step.isRecurrencePaymentStep) || false
}

function getValueToDisplay(value, { radioOptions, selectOptions, inputType }) {
  if (inputType === 'form-content') {
    return
  }
  if (inputType === 'input-adder') {
    const isStringFormat = typeof value === 'string'
    const isArrayFormat = Array.isArray(value)

    if (isStringFormat) {
      return value
      return value.split(', ').map((item, index) => ({
        id: index,
        value: item,
      }))
    }
    if (isArrayFormat) {
      return value.map((item) => item.value).join(', ') || 'Nessun partecipante'
    }
    return value.map((item) => item.value).join(', ') || 'Nessun partecipante'
  }
  if (radioOptions && radioOptions.length > 0) {
    const selectedOption = radioOptions.find((option) => option.value === value)
    if (selectedOption) return selectedOption.label
  }
  if (typeof selectOptions === 'object' && selectOptions.length > 0) {
    const selectedOption = selectOptions.find(
      (option) => option.value === value
    )
    if (selectedOption) return selectedOption.label
  }
  if (typeof value === 'string' && value?.includes('custom-number-')) {
    return value.replace('custom-number-', '').concat(' €')
  }
  if (typeof value === 'string') {
    if (value === 'one-time') {
      return 'Singola'
    }
    if (value === 'monthly') {
      return 'Mensile'
    }
    if (value === 'yearly') {
      return 'Annuale'
    }
  }
  if (
    (typeof value === 'string' &&
      (value?.includes('true') || value?.includes('false'))) ||
    typeof value === 'boolean'
  ) {
    return value === 'true' || value === true ? 'Accettato' : 'Non accettato'
  }
  return value
}

function getLabelToDisplay(label, { uiOptions }) {
  return uiOptions?.recapLabel || label || ''
}

function getFieldsEdits(formState, formFields) {
  const editFields = new Map(
    Object.entries(formState).filter(([fieldName]) =>
      fieldName.includes('-edit')
    )
  )
  const originalFields = new Map(
    Object.entries(formState).filter(
      ([fieldName]) => !fieldName.includes('-edit')
    )
  )
  const fieldNameToExclude = { includes: ['donationLetterPartecipants-'] }

  const changes = Array.from(editFields).reduce(
    (acc, [fieldName, fieldValue]) => {
      const originalFieldName = fieldName.replace('-edit', '')
      const { includes = [] } = fieldNameToExclude

      const isExcluded = includes.some((exclude) =>
        originalFieldName.startsWith(exclude)
      )
      if (isExcluded) return acc
      const originalValue = originalFields.get(originalFieldName)
      const fieldProps =
        formFields.find((field) => field.fieldName === originalFieldName) || {}

      if (
        originalValue !== fieldValue &&
        JSON.stringify(originalValue) !== JSON.stringify(fieldValue) &&
        originalValue !== undefined &&
        originalValue !== '' &&
        originalValue !== null &&
        fieldValue !== undefined &&
        fieldValue !== null &&
        fieldValue !== ''
      ) {
        acc[originalFieldName] = {
          ...fieldProps,
          newValue: fieldValue,
          oldValue: originalValue,
        }
      }
      return acc
    },
    {}
  )
  changes.editNumber = Object.keys(changes).length
  return changes
}

function clearAllEditFields(formState, unregister, clearErrors, reset) {
  const editFields = Object.entries(formState)
    .filter(([fieldName]) => fieldName.includes('-edit'))
    .map(([fieldName, fieldValue]) => fieldName)
  if (editFields.length < 1) return
  clearErrors(editFields)
  unregister(editFields)
}

function getAllWatchedFields(fields) {
  return [
    ...new Set(
      fields
        .map((field) => field.uiOptions?.watchFields)
        .map((watchFields) => {
          if (!watchFields) return
          return watchFields.map(({ field }) => field)
        })
        .flat()
        .filter((field) => field)
    ),
  ]
}

function getFieldNamesInView(fields, step, watch = () => {}) {
  // Group fields by their respective steps
  const fieldGroupedByStep = groupFieldsByStep(fields)

  // If there are no fields for the given step, return an empty array
  if (!fieldGroupedByStep[step]) return []

  const fieldNames = fieldGroupedByStep[step]
    .filter((field) => {
      // Check if the field has any watchFields/sub-fields defined in its uiOptions
      const isWatching = field.uiOptions?.watchFields?.length > 0 || false

      if (isWatching) {
        const watchList = field.uiOptions?.watchFields?.map((watchField) => {
          if (watchField.includes && watchField.includes.length > 0) {
            const { field, includes } = watchField
            const watchedValue = watch(field)
            return watchedValue ? includes.includes(watchedValue) : false
          }
          if (watchField.equals !== undefined) {
            const { field, equals } = watchField
            const watchedValue = String(watch(field))
            return watchedValue || watchedValue === ''
              ? equals === watchedValue
              : false
          }
        })
        return watchList.every(Boolean)
      } else {
        return true
      }
    })
    .map((field) => field.fieldName)

  return fieldNames
}

function getFieldNamesInRecapAccordion(
  stepHistory,
  formFields,
  watch,
  getValues
) {
  const fieldNamesInRecapAccordion = stepHistory
    .map((step) => {
      return getFieldNamesInView(formFields, step, watch)
    })
    .flat()

  const fieldsInRecapAccordion = fieldNamesInRecapAccordion.map((fieldName) => {
    return {
      ...formFields.find((field) => field.fieldName === fieldName),
      value: getValues(fieldName),
    }
  })

  return fieldsInRecapAccordion
}

export {
  clearAllEditFields,
  evaluateCondition,
  getAllWatchedFields,
  getDefaultValues,
  getFieldNamesInRecapAccordion,
  getFieldNamesInView,
  getFieldsEdits,
  getLabelToDisplay,
  getValueToDisplay,
  groupFieldsByEdit,
  groupFieldsByStep,
  isPaymentStep,
  isRecurrencePaymentStep,
}
