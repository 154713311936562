/* ---------------------------------
Anchor
--------------------------------- */

import Link from 'next/link'
import classNames from 'classnames'
import { extractStyleProps } from 'utils/styleProps'

export default function Anchor({
  children,
  styleProps,
  variant,
  ...linkProps
}) {
  return (
    <span
      className={classNames(
        'Anchor',
        variant === 'white' && 'Anchor--color-white',
        extractStyleProps(styleProps)
      )}
    >
      {/* If a button is needed, modify accordingly */}
      <Link {...linkProps}>{children}</Link>
    </span>
  )
}
