import classNames from 'classnames'

const Avatar = ({
  numberOfAvatar,
  variant,
  className,
  label = '+3000 donatori',
}) => {
  const renderAvatar = () => {
    const randomNumber = Math.floor(Math.random() * 50)
    const avatarList = []
    for (let i = 0; i < numberOfAvatar; i++) {
      avatarList.push(
        <div
          className={classNames(
            'avatar-child',
            variant === 'embedded' && 'avatar-child--embedded'
          )}
          key={i}
          style={{
            backgroundImage: `url(https://i.pravatar.cc/150?img=${
              randomNumber + i
            })`,
          }}
        ></div>
      )
    }
    return avatarList
  }

  return (
    <div className={classNames('avatar-list', className)}>
      {renderAvatar()}
      {label ? <span>{label}</span> : null}
    </div>
  )
}

export default Avatar
