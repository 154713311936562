/* ---------------------------------
TextCard
--------------------------------- */

import Card from '../../atoms/Card'
import classNames from 'classnames'
import { extractStyleProps } from 'utils/styleProps'

export default function TextCard({
  title,
  children,
  className,
  ...styleProps
}) {
  return (
    <Card
      className={classNames(
        'TextCard',
        className,
        extractStyleProps(styleProps)
      )}
    >
      {title ? <span className={'TextCard__title'}>{title}</span> : null}

      <div className='TextCard__body'>{children}</div>
    </Card>
  )
}
