/* ---------------------------------
HighlightCard
--------------------------------- */

import classNames from 'classnames'
import { extractStyleProps } from 'utils/styleProps'
import PropTypes from 'prop-types'
import { Card } from 'components/atoms'

export default function HighlightCard({
  title,
  children,
  className,
  ...styleProps
}) {
  return (
    <Card
      variant={'bare'}
      className={classNames(
        'HighlightCard',
        className,
        extractStyleProps(styleProps)
      )}
    >
      <div className='HighlightCard__container'>
        <div className='HighlightCard__indicator'>
          <span />
        </div>

        <div className='HighlightCard__content'>
          {title ? (
            <span className={'HighlightCard__title'}>{title}</span>
          ) : null}

          <div className='HighlightCard__body'>{children}</div>
        </div>
      </div>
    </Card>
  )
}

HighlightCard.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  styleProps: PropTypes.object,
}
