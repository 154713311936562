'use client'

import { AnimatePresence, motion } from 'framer-motion'
import { useState } from 'react'
import { FaChevronDown } from 'react-icons/fa6'

const AccordionItem = ({
  topLabel = '',
  title,
  coloredTitle,
  content,
  index,
  isOpen,
  toggleOpen,
  classNames,
  openDirection = 'down',
}) => {
  return (
    <div
      className={`${classNames.accordionItem} ${
        isOpen ? `${classNames.open} blur-background` : ''
      }
    ${openDirection === 'up' ? 'up' : ''}`}
    >
      <motion.header
        className={`${classNames.accordionHeader} ${
          isOpen ? classNames.open : ''
        }`}
        initial={false}
        onClick={() => toggleOpen(isOpen ? false : index)}
      >
        <div className={`${classNames.accordionHeader}_text-wrap`}>
          <span className='top-title'>{topLabel}</span>
          <span>
            {title} <span className='colored-brand-blue'> {coloredTitle}</span>
          </span>
        </div>
        <FaChevronDown className='chevron' />
      </motion.header>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.section
            className={`${classNames.accordionPanel} ${
              isOpen ? classNames.open : ''
            }`}
            key='content'
            initial='collapsed'
            animate='open'
            exit='collapsed'
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            {content}
          </motion.section>
        )}
      </AnimatePresence>
    </div>
  )
}

const Accordion = ({
  items,
  className,
  classNames = {
    accordionWrapper: 'accordion',
    accordionItem: 'accordion-item',
    accordionHeader: 'accordion-header',
    accordionButton: 'accordion-button',
    accordionPanel: 'accordion-panel',
    open: 'open',
  },
  isModalBehavior = false,
  onToggle = () => {},
  closeAll = false,
  isUnstyled = false,
  openDirection = 'down',
  topLabel = '',
}) => {
  const [expanded, setExpanded] = useState(closeAll)

  const toggleOpen = (index) => {
    setExpanded(expanded === index ? false : index)
    onToggle(index)
  }

  if (closeAll && expanded !== false) {
    setExpanded(false)
  }

  return (
    <div
      className={`${classNames.accordionWrapper} ${
        isModalBehavior ? 'modal-accordion' : ''
      }${className ? ` ${className}` : ''} ${isUnstyled ? 'unstyled' : ''}`}
      data-items={items.length}
    >
      {items.map((item, index) => (
        <AccordionItem
          key={index}
          index={index}
          isOpen={expanded === index}
          toggleOpen={toggleOpen}
          title={item.title}
          coloredTitle={item.coloredTitle}
          topLabel={topLabel}
          content={item.content}
          classNames={classNames}
          openDirection={openDirection}
        />
      ))}
    </div>
  )
}

export default Accordion
