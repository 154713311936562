/* ---------------------------------
Title
--------------------------------- */

import classNames from 'classnames'
import { Emph } from 'components/atoms'
import { createElement, useMemo } from 'react'
import { extractStyleProps } from 'utils/styleProps'

const parseStringWithEmph = (text) => {
  if (typeof text !== 'string') return text

  const parts = text.split(/(<Emph>.*?<\/Emph>)/)
  return parts.map((part, index) => {
    const emphMatch = part.match(/<Emph>(.*?)<\/Emph>/)
    if (emphMatch) {
      return <Emph key={index}>{emphMatch[1]}</Emph>
    }
    return part
  })
}

export default function Title({
  level = 1,
  negative,
  className,
  children,
  ...styleProps
}) {
  const type = useMemo(() => getElement(level), [level])
  const processedChildren = useMemo(
    () => parseStringWithEmph(children),
    [children]
  )

  return createElement(
    type,
    {
      className: classNames(
        'Title',
        level === 1 && 'Title--h1',
        level === 2 && 'Title--h2',
        level === 3 && 'Title--h3',
        level === 4 && 'Title--h4',
        level === 5 && 'Title--h5',
        level === 6 && 'Title--h6',
        negative && `Title--negative`,
        className,
        extractStyleProps(styleProps)
      ),
    },
    processedChildren
  )
}

function getElement(level) {
  switch (level) {
    case 2:
      return 'h2'

    case 3:
      return 'h3'

    case 4:
      return 'h4'

    case 5:
      return 'h5'

    case 6:
      return 'h6'

    case 1:
    default:
      return 'h1'
  }
}
