/* ---------------------------------
StyleProps
--------------------------------- */

export function extractStyleProps(props) {
  // will be discarded by classNames
  if (!props) return null

  // the array will be expanded
  // by classNames as individual classes
  return Object.keys(props).length
    ? // a truthy value is not enough, we're looking for an actual `true`
      Object.keys(props).filter((k) => props[k] === true)
    : null
}
