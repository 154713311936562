'use client'

/* ---------------------------------
CampaignSection
--------------------------------- */

import {
  Avatar,
  Button,
  OpeningTitle,
  Paragraph,
  Section,
  Title,
} from 'components/atoms'
import { ProgressCard } from 'components/molecules'
import { useMediaQuery } from '@uidotdev/usehooks'
import PropTypes from 'prop-types'
import { useRouter } from 'next/navigation'
import classNames from 'classnames'

export default function CampaignSection({ data }) {
  const { attributes } = data ?? {}
  const {
    openingTitle,
    title,
    paragraph,
    ctaButton,
    // progressCard, TODO
    layout,
    bg: sectionBg,
    textAlign,
    paragraphPosition,
  } = attributes ?? {}

  const router = useRouter()

  const isMD = useMediaQuery('only screen and (min-width: 768px)')
  const isLG = useMediaQuery('only screen and (min-width: 1024px)')
  const isXL = useMediaQuery('only screen and (min-width: 1440px)')
  const isXXL = useMediaQuery('only screen and (min-width: 1920px)')

  return (
    <Section
      bg={sectionBg}
      className={classNames(
        'CampaignSection',
        layout === 'single_col' && 'CampaignSection--layout_single_col',
        layout === 'invert_cols' && 'CampaignSection--layout_invert_cols',
        paragraphPosition === 'bottom' && 'CampaignSection--layout_parag_bottom'
      )}
      pyXXL={isXL}
    >
      <div className='CampaignSection__grid'>
        <div className='CampaignSection__grid__col-1'>
          {openingTitle ? (
            <OpeningTitle
              taLeft={isLG ? layout !== 'single_col' : textAlign === 'left'}
            >
              {openingTitle}
            </OpeningTitle>
          ) : null}

          {title ? (
            <Title
              mt0
              mbXS
              level={2}
              taLeft={isLG ? layout !== 'single_col' : textAlign === 'left'}
            >
              <span dangerouslySetInnerHTML={{ __html: title }} />
            </Title>
          ) : null}

          {paragraph && (isMD || paragraphPosition !== 'bottom') ? (
            <Paragraph
              mbL
              taLeft={isLG ? layout !== 'single_col' : textAlign === 'left'}
            >
              {paragraph}
            </Paragraph>
          ) : null}

          {ctaButton ? (
            <Button
              className={'CampaignSection__cta-button'}
              variant={'cta'}
              // TODO what if it's a fully qualified URL
              onClick={() => router.push(ctaButton.url)}
            >
              {ctaButton.label}
            </Button>
          ) : null}
        </div>

        <div className='CampaignSection__grid__col-2'>
          {1 ? (
            // TODO move inlined data into the mock
            <ProgressCard
              className={'CampaignSection__progress-card'}
              figures={{
                part: 6500,
                whole: 10000,
                unit: '€',
              }}
              testimonials={
                <Avatar
                  variant={'embedded'}
                  numberOfAvatar={3}
                />
              }
              ctaMessage={'+1000 hanno già donato'}
            />
          ) : null}

          {paragraph && !isMD && paragraphPosition === 'bottom' ? (
            <Paragraph
              mtL
              taLeft={
                isLG
                  ? textAlign === 'left' && layout !== 'single_col'
                  : textAlign === 'left'
              }
            >
              {paragraph}
            </Paragraph>
          ) : null}
        </div>
      </div>
    </Section>
  )
}

CampaignSection.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    attributes: PropTypes.shape({
      // this would represent a CMS enumeration
      layout: PropTypes.oneOf(['invert_cols', 'single_col']),
      bg: PropTypes.oneOf([
        'dark',
        'black',
        'blue',
        'dark-blue',
        'dark-green',
        'green',
        'grey-050',
        'grey-100',
        'grey-200',
        'grey-300',
        'grey-500',
        'grey-700',
        'grey-900',
        'rich-blue',
        'white',
      ]),
      textAlign: PropTypes.oneOf(['left', 'center']),
      paragraphPosition: PropTypes.oneOf(['top', 'bottom']),
      openingTitle: PropTypes.string,
      title: PropTypes.string,
      paragraph: PropTypes.string,
      ctaButton: PropTypes.shape({
        label: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
      progressCard: PropTypes.shape({
        data: PropTypes.shape({
          id: PropTypes.number,
          attributes: PropTypes.shape({
            figures: PropTypes.shape({
              part: PropTypes.number.isRequired,
              whole: PropTypes.number.isRequired,
              unit: PropTypes.string.isRequired,
            }).isRequired,
            testimonials: PropTypes.node,
            ctaMessage: PropTypes.string,
            createdAt: PropTypes.string,
            updatedAt: PropTypes.string,
            publishedAt: PropTypes.string,
          }),
        }),
      }),
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      publishedAt: PropTypes.string,
    }),
  }).isRequired,
}
