/* ---------------------------------
PlayButton
--------------------------------- */

import PropTypes from 'prop-types'
import classNames from 'classnames'
import { extractStyleProps } from 'utils/styleProps'
import { IconButton } from 'components/atoms'
import { TbPlayerPlayFilled } from 'react-icons/tb'

export default function PlayButton({ children, className, ...styleProps }) {
  return (
    <span
      className={classNames(
        'PlayButton',
        className,
        extractStyleProps(styleProps)
      )}
    >
      <IconButton className={'PlayButton__icon'}>
        <TbPlayerPlayFilled
          color={'#fff'}
          size={32}
        />
      </IconButton>
    </span>
  )
}

PlayButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  styleProps: PropTypes.object,
}
