/* ---------------------------------
Container
--------------------------------- */

import classNames from 'classnames'
import { extractStyleProps } from 'utils/styleProps'
import PropTypes from 'prop-types'

export default function Container({
  size,
  children,
  className,
  ...styleProps
}) {
  return (
    <div
      className={classNames(
        'Container',
        className,
        // the redundancy with styleProps is intended,
        // since maxWidth via styleProps can be imparted
        // to any component and not only this one.
        size === 'SM' && 'Container--mwSM',
        size === 'MD' && 'Container--mwMD',
        size === 'LG' && 'Container--mwLG',
        size === 'XL' && 'Container--mwXL',
        size === 'XXL' && 'Container--mwXXL',
        size === '3XL' && 'Container--mw3XL',
        extractStyleProps(styleProps)
      )}
    >
      {children}
    </div>
  )
}

Container.propTypes = {
  size: PropTypes.oneOf(['SM', 'MD', 'LG', 'XL', 'XXL', '3XL']),
  children: PropTypes.node,
  className: PropTypes.string,
  ...PropTypes.object,
}
