const reducerActionsKeys = {
  SET_CURRENT_STEP: 'SET_CURRENT_STEP',
  SET_EDIT_MODE: 'SET_EDIT_MODE',
  SET_SELECTED_EDIT_GROUP: 'SET_SELECTED_EDIT_GROUP',
  UPDATE_RECAP_ACCORDION_STATE: 'UPDATE_RECAP_ACCORDION_STATE',
  HANDLE_BACK_FROM_SELECTOR_EDIT: 'HANDLE_BACK_FROM_SELECTOR_EDIT',
  HANDLE_BACK_FROM_FIELDS_EDIT: 'HANDLE_BACK_FROM_FIELDS_EDIT',
  HANDLE_EDIT_FORM: 'HANDLE_EDIT_FORM',
  HANDLE_SAVE_EDIT: 'HANDLE_SAVE_EDIT',
  HANDLE_CONFIRM_EDIT: 'HANDLE_CONFIRM_EDIT',
}

const formInitialState = {
  currentStep: 1,
  stepHistory: [1],
  isEditMode: false,
  selectedEditGroup: null,
  recapAccordionState: false,
  forceCloseRecap: false,
  recapTopLabel: '',
}

function formReducer(state, action) {
  const {
    SET_CURRENT_STEP,
    SET_EDIT_MODE,
    SET_SELECTED_EDIT_GROUP,
    UPDATE_RECAP_ACCORDION_STATE,
    HANDLE_BACK_FROM_SELECTOR_EDIT,
    HANDLE_BACK_FROM_FIELDS_EDIT,
    HANDLE_EDIT_FORM,
    HANDLE_SAVE_EDIT,
    HANDLE_CONFIRM_EDIT,
  } = reducerActionsKeys
  switch (action.type) {
    case SET_CURRENT_STEP: {
      const { currentStep, stepHistory, direction } = action.payload
      const mustResetLabel = direction === 'previous'
      return {
        ...state,
        currentStep,
        stepHistory,
        recapTopLabel: mustResetLabel ? '' : state.recapTopLabel,
      }
    }
    case UPDATE_RECAP_ACCORDION_STATE: {
      const { recapAccordionState } = action.payload
      const mustResetLabel = state.recapTopLabel === 'Appena aggiornato'
      return {
        ...state,
        forceCloseRecap: false,
        recapAccordionState,
        recapTopLabel: mustResetLabel ? '' : state.recapTopLabel,
      }
    }
    case HANDLE_BACK_FROM_SELECTOR_EDIT:
      return {
        ...state,
        selectedEditGroup: null,
        forceCloseRecap: false,
        isEditMode: false,
        recapTopLabel: '',
      }
    case HANDLE_CONFIRM_EDIT:
      return {
        ...state,
        recapAccordionState: false,
        forceCloseRecap: true,
      }
    case HANDLE_BACK_FROM_FIELDS_EDIT:
      return {
        ...state,
        selectedEditGroup: null,
        recapTopLabel: '',
      }
    case HANDLE_EDIT_FORM:
      return {
        ...state,
        recapAccordionState: false,
        forceCloseRecap: true,
        isEditMode: true,
      }

    case HANDLE_SAVE_EDIT:
      const { editNumber } = action.payload
      let recapTopLabel = ''
      if (editNumber > 0) {
        recapTopLabel = 'Appena aggiornato'
      }
      return {
        ...state,
        forceCloseRecap: false,
        isEditMode: false,
        selectedEditGroup: null,
        recapTopLabel,
      }
    case SET_SELECTED_EDIT_GROUP:
      return {
        ...state,
        selectedEditGroup: action.payload,
      }
    case SET_EDIT_MODE:
      return {
        ...state,
      }
    default:
      return state
  }
}
const reducerActions = {
  SET_CURRENT_STEP: (payload) => ({
    type: reducerActionsKeys.SET_CURRENT_STEP,
    payload,
  }),
  UPDATE_RECAP_ACCORDION_STATE: (payload) => ({
    type: reducerActionsKeys.UPDATE_RECAP_ACCORDION_STATE,
    payload,
  }),
  SET_EDIT_MODE: (payload) => ({
    type: reducerActionsKeys.SET_EDIT_MODE,
    payload,
  }),
  HANDLE_BACK_FROM_SELECTOR_EDIT: () => ({
    type: reducerActionsKeys.HANDLE_BACK_FROM_SELECTOR_EDIT,
  }),
  HANDLE_BACK_FROM_FIELDS_EDIT: () => ({
    type: reducerActionsKeys.HANDLE_BACK_FROM_FIELDS_EDIT,
  }),
  HANDLE_EDIT_FORM: () => ({
    type: reducerActionsKeys.HANDLE_EDIT_FORM,
  }),
  HANDLE_SAVE_EDIT: (payload) => ({
    type: reducerActionsKeys.HANDLE_SAVE_EDIT,
    payload,
  }),
  SET_SELECTED_EDIT_GROUP: (payload) => ({
    type: reducerActionsKeys.SET_SELECTED_EDIT_GROUP,
    payload,
  }),
  HANDLE_CONFIRM_EDIT: () => ({
    type: reducerActionsKeys.HANDLE_CONFIRM_EDIT,
  }),
}

export { formInitialState, formReducer, reducerActions, reducerActionsKeys }
