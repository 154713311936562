/* ---------------------------------
StickyCTA
--------------------------------- */

import { Button } from 'components/atoms'
import PropTypes from 'prop-types'

export default function StickyCTA({ action }) {
  return (
    <div className={'StickyCTA'}>
      <Button
        className={'StickyCTA__cta-button'}
        variant={'cta'}
        onClick={action}
      >
        Dona ora
      </Button>
    </div>
  )
}

StickyCTA.propTypes = {
  action: PropTypes.func.isRequired,
}
