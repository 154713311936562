const freeDonation = [
  'companyname:1529',
  'initial_privacy:1635',
  'termsandconditions:1636',
  'amount:1762=altro&1761',
  'fixedAmount:1762',
  'amountType:1763',
  'recurrenceType:1764',
  'paymentContinuslyType:1765',
  'firstName:1766',
  'lastName:1767',
  'address:1768',
  'number:1769',
  'cap:1770',
  'city:1771',
  'province:1772',
  'email:1773',
  'fiscalcode:1774',
  'taxId:1774',
  'dob:1775',
  'sex:1776',
  'cell:1777',
  'bonificFirstName:1779',
  'bonificLastName:1780',
  'bonificIban:1781',
  'amountDetails:1782',
  'amountParam:1783',
  'ets_cmmk:1784',
  'ets_sgmt:1785',
  '_ga:1786',
  'donationType:1788',
  'finalization1:1789',
  'finalization2:1790',
  'promoter:1791',
  'fhSource:1792',
  'successUrl:1793',
  'cancelUrl:1794',
  'vat:1795',
  'nation:2690',
  'acceptTerms:accettaTermini',
  'phone:XXXX',
  'ets_sggt:2691',
  'ets_sggt_t:2692',
]

const freeDonationCodes = {
  donatorCompanyName: {
    legacyKey: 'companyname',
    iframeCode: {
      freeDonation: 1529,
    },
  },
  privacy: {
    legacyKey: 'initial_privacy',
    iframeCode: {
      freeDonation: (isChecked) => (isChecked ? `1635=1` : `1635=0`),
    },
  },
  termsAndConditions: {
    legacyKey: 'termsandconditions',
    iframeCode: {
      freeDonation: (isChecked) => (isChecked ? `1636=1` : `1636=0`),
    },
  },
  donationAmount: {
    legacyKey: 'amount',
    iframeCode: {
      freeDonation: (value) => `1762=altro&1761=${value}`,
    },
  },
  donationType: {
    legacyKey: 'amountType',
    iframeCode: {
      freeDonation: (value) =>
        value === 'in-memory' ? `1763=591&1788=591` : `1763=590&1788=590`,
    },
  },
  donationFrequency: {
    legacyKey: 'recurrenceType',
    iframeCode: {
      freeDonation: (donationFrequency) => {
        if (donationFrequency === 'monthly') return `1764=6`
        if (donationFrequency === 'yearly') return `1764=12`
        return `1764=INVALID`
      },
    },
  },
  donationPaymentMethodOnRecurrenceType: {
    legacyKey: 'paymentContinuslyType',
    iframeCode: {
      freeDonation: (paymentMethod) => {
        if (paymentMethod === 'card') return `1765=Carta_di_credito`
        if (paymentMethod === 'iban') return `1765=Conto_corrente`
        return `1765=INVALID`
      },
    },
  },
  donatorFirstName: {
    legacyKey: 'firstname',
    iframeCode: {
      freeDonation: 1766,
    },
  },
  donatorLastName: {
    legacyKey: 'lastname',
    iframeCode: {
      freeDonation: 1767,
    },
  },
  donatorAddress: {
    legacyKey: 'address',
    iframeCode: {
      freeDonation: 1768,
    },
  },
  donatorAddressNumber: {
    legacyKey: 'number',
    iframeCode: {
      freeDonation: 1769,
    },
  },
  donatorPostalCode: {
    legacyKey: 'cap',
    iframeCode: {
      freeDonation: 1770,
    },
  },
  donatorCity: {
    legacyKey: 'city',
    iframeCode: {
      freeDonation: 1771,
    },
  },
  donatorProvince: {
    legacyKey: 'province',
    iframeCode: {
      freeDonation: 1772,
    },
  },
  donatorEmail: {
    legacyKey: 'email',
    iframeCode: {
      freeDonation: 1773,
    },
  },
  donatorTaxCode: {
    legacyKey: ['fiscalcode', 'taxId'],
    iframeCode: {
      freeDonation: 1774,
    },
  },
  donatorBirthdate: {
    legacyKey: 'birthdate',
    iframeCode: {
      freeDonation: 1775,
    },
  },
  donatorGender: {
    legacyKey: 'sex',
    iframeCode: {
      freeDonation: (gender) => (gender === 'M' ? `1776=1` : `1776=2`),
    },
  },
  donatorCellphone: {
    legacyKey: 'cell',
    iframeCode: {
      freeDonation: 1777,
    },
  },
  donationIbanOwnerFirstName: {
    legacyKey: 'bonificFirstname',
    iframeCode: {
      freeDonation: 1779,
    },
  },
  donationIbanOwnerLastName: {
    legacyKey: 'bonificLastname',
    iframeCode: {
      freeDonation: 1780,
    },
  },
  donationIbanOwnerTaxCode: {
    legacyKey: 'bonificFiscalCode',
    iframeCode: {
      freeDonation: 1780,
    },
  },
  donationPaymentMethodOnRecurrenceIban: {
    legacyKey: 'bonificIban',
    iframeCode: {
      freeDonation: 1781,
    },
  },
  donatorCountry: {
    legacyKey: 'nation',
    iframeCode: {
      freeDonation: 2690,
    },
  },
}

const iframeIncludes = {
  successUrl: {
    value: '/dona/successo',
    iframeCode: {
      freeDonation: 1793,
    },
  },
  cancelUrl: {
    value: '/dona/errore',
    iframeCode: {
      freeDonation: 1794,
    },
  },
  acceptTerms: {
    value: '1',
    iframeCode: {
      freeDonation: 'accettaTermini',
    },
  },
  fhSource: {
    value: (isInMemory) => (isInMemory ? '1619' : `284`),
    iframeCode: {
      freeDonation: 1792,
    },
  },
  promoter: {
    value: '1011063',
    iframeCode: {
      freeDonation: 1791,
    },
  },
  finalization1: {
    value: '16903',
    iframeCode: {
      freeDonation: 1789,
    },
  },
  finalization2: {
    value: '16903',
    iframeCode: {
      freeDonation: 1790,
    },
  },
}

const freeDonationFixedValues = {
  finalization1: {
    value: '16903',
    iframeCode: {
      freeDonation: 1789,
    },
  },
  finalization2: {
    value: '1',
    iframeCode: {
      freeDonation: 1790,
    },
  },
}

export const iFrameUrlBuilder = (formValues = {}) => {
  const isInMemory = formValues.donationType === 'in-memory'
  const baseUrl = isInMemory
    ? process.env.recurrentInMemoryPaymentUrl
    : process.env.recurrentPaymentUrl
  const queryParams = []

  for (const [fieldName, codeConfig] of Object.entries(freeDonationCodes)) {
    const formValue = formValues[fieldName]
    if (formValue === undefined || formValue === '') continue

    const { iframeCode } = codeConfig

    // Handle special cases where iframeCode is a function
    if (typeof iframeCode.freeDonation === 'function') {
      const paramString = iframeCode.freeDonation(formValue)
      queryParams.push(paramString)
      continue
    }

    // Handle standard cases where iframeCode is a number
    queryParams.push(
      `${iframeCode.freeDonation}=${encodeURIComponent(formValue)}`
    )
  }

  for (const [fieldName, codeConfig] of Object.entries(iframeIncludes)) {
    const formValue = codeConfig.value
    if (formValue === undefined || formValue === '') continue

    const { iframeCode } = codeConfig

    // Handle special cases where iframeCode is a function
    if (typeof codeConfig.value === 'function') {
      const paramString = `${
        codeConfig.iframeCode.freeDonation
      }=${codeConfig.value(isInMemory)}`
      queryParams.push(paramString)
      continue
    }

    // Handle standard cases where iframeCode is a number
    queryParams.push(
      `${iframeCode.freeDonation}=${encodeURIComponent(formValue)}`
    )
  }

  // console.log('queryParams', queryParams)
  // Build the final URL
  return `${baseUrl}${queryParams.length ? '?&' : ''}${queryParams.join('&')}`
}
