const Spinner = () => {
  return (
    <div className='loader-wrapper'>
      <span className='loader'></span>
      <span className='loader-text'></span>
    </div>
  )
}

export default Spinner
