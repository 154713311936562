'use client'
// components/Modal/Modal.jsx

import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

const Modal = ({
  isOpen,
  onClose,
  children,
  classNames = {
    modalOverlay: 'modal-overlay',
    modalContent: 'modal-content',
    closeButton: 'close-button',
  },
  closeLabel = 'Chiudi',
}) => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
      // Trigger visibility after component mounts
      setTimeout(() => {
        setIsVisible(true)
      }, 0)
    } else {
      setIsVisible(false)
      document.body.style.overflow = 'auto'
    }
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isOpen])

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        onClose()
      }
    }
    if (isOpen) {
      window.addEventListener('keydown', handleEsc)
    }
    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [isOpen, onClose])

  return ReactDOM.createPortal(
    <div
      className={`${classNames.modalOverlay} ${isVisible ? 'modal-open' : ''}`}
      onClick={isOpen ? onClose : null}
    >
      <div
        className={`${classNames.modalContent} ${
          isVisible ? 'modal-open' : ''
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
      <ModalCloseButton
        ariaLabel={'Chiudi modal'}
        className={classNames.closeButton}
        onClick={onClose}
      >
        {closeLabel}
      </ModalCloseButton>
    </div>,
    document.body
  )
}

function ModalCloseButton({
  children,
  onClick,
  className = 'close-button',
  ariaLabel = 'Chiudi modal',
}) {
  return (
    <button
      className={className}
      onClick={onClick}
      aria-label={ariaLabel}
    >
      {children}
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='25'
        height='24'
        viewBox='0 0 25 24'
        fill='none'
      >
        <path
          d='M18.864 5.63599L6.13609 18.3639'
          stroke='#FAFAFA'
          stroke-width='1.5'
          stroke-linecap='round'
        />
        <path
          d='M6.13599 5.63599L18.8639 18.3639'
          stroke='#FAFAFA'
          stroke-width='1.5'
          stroke-linecap='round'
        />
      </svg>
    </button>
  )
}

export default Modal
