'use client'

import { AnimatePresence, motion } from 'framer-motion'

import { Input } from 'components/molecules'
import { useQueryState } from 'nuqs'
import React, { useEffect, useRef } from 'react'
import { useFormContext } from 'react-hook-form'

const InputAdder = ({
  maxInputList: _maxInputList = 4,
  name,
  useFormContextIfAvailable = true,
  config,
  defaultValue: _defaultValue = '',
  disableQueryState = false,
  errorLabel,
  placeholder,
  ...rest
}) => {
  const defaultValue =
    typeof _defaultValue === 'string' ? _defaultValue.split(',') : _defaultValue

  const nextId = useRef(0)

  const nextIdFunc = () => nextId.current++
  const maxInputList = _maxInputList || 2

  const [inputListQs, setInputListQs] = useQueryState(name, {
    defaultValue,
    throttleMs: disableQueryState ? +Infinity : 600,
    parse: (value) => {
      if (!value) return [{ id: nextId.current++, value: '' }]
      return value
        .split(',')
        .filter((item) => item !== '')
        .map((item) => ({
          id: nextId.current++,
          value: item,
        }))
    },
    serialize: (value) => value.map((item) => item.value).join(','),
  })
  const [inputListState, setInputListState] = React.useState(defaultValue)
  const inputList = disableQueryState ? inputListState : inputListQs
  const setInputList = disableQueryState ? setInputListState : setInputListQs

  const formContext = useFormContext()
  const areAllValuesNotEmpty = inputList.every((input) => input.value !== '')
  const isFieldRequired = config?.required?.value ?? false

  let inputAdderProps = {}
  if (formContext && useFormContextIfAvailable) {
    const { register } = formContext
    inputAdderProps = {
      ...register(name, config),
    }
  }

  const handleChange = (id, event) => {
    const newValue = event.target.value
    setInputList((prevInputList) => {
      const newInputList = prevInputList.map((input) => {
        if (input.id === id) {
          return { ...input, value: newValue }
        }
        return input
      })

      // Se si sta digitando nell'ultimo input, aggiungere un nuovo input se necessario
      const isLastInput = newInputList[newInputList.length - 1].id === id
      const isNotEmpty = newValue !== ''
      if (isLastInput && isNotEmpty && newInputList.length < maxInputList) {
        newInputList.push({ id: nextId.current++, value: '' })
      }

      return newInputList
    })
  }

  const removeItem = (id) => {
    setInputList((prevInputList) => {
      let newInputList = prevInputList.filter((input) => input.id !== id)
      // Se l'ultimo input non è vuoto e non si è raggiunto il massimo, aggiungere un input vuoto
      if (
        newInputList.length < maxInputList &&
        newInputList[newInputList.length - 1]?.value !== ''
      ) {
        newInputList.push({ id: nextId.current++, value: '' })
      }
      return newInputList.length > 0
        ? newInputList
        : [{ id: nextId.current++, value: '' }]
    })
  }

  useEffect(() => {
    if (inputList.length === 0) {
      setInputList([{ id: nextId.current++, value: '' }])
    }
    if (inputList.length < maxInputList && areAllValuesNotEmpty) {
      const newInputList = [...inputList]
      newInputList.push({ id: nextId.current++, value: '' })
      setInputList(newInputList)
    }
    if (formContext && useFormContextIfAvailable) {
      const trimmmedInputList = inputList.filter((input) => input.value !== '')
      formContext.setValue(name, trimmmedInputList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areAllValuesNotEmpty, inputList, maxInputList])

  // Aggiorna il valore nel form context quando la lista cambia
  // useEffect(() => {
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [inputList])
  return (
    <div
      {...inputAdderProps}
      className='input-adder'
    >
      <div className='input-adder_input-wrap'>
        <AnimatePresence>
          {inputList.map((input, index) => (
            <motion.div
              key={`${name}-${input.id}`}
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0 }}
            >
              <Input
                key={`${name}-${input.id}`}
                keyName={`${name}-${input.id}`}
                type='text'
                placeholder={
                  index === inputList.length - 1
                    ? 'Scrivi per aggiungere'
                    : placeholder
                }
                defaultValue={input.value}
                name={`${name}-${input.id}`}
                value={typeof input.value === 'string' ? input.value : ''}
                onChange={(e) => handleChange(input.id, e)}
                useFormContextIfAvailable={false}
                disableQueryState={true}
                onCloseIconClick={() => {
                  removeItem(input.id)
                }}
                hasCloseIcon={
                  input.value !== '' || index !== inputList.length - 1
                }
              />
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
      {inputList.length === maxInputList && (
        <p className='input-adder_disclaimer'>
          {`${errorLabel} ( ${maxInputList} ) `}
        </p>
      )}
    </div>
  )
}

export default InputAdder
